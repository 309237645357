//import TextInput from 'components/inputs/Inputs';
import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import FormikDropDown from 'components/inputs/FormikDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { changeStep } from 'actions/formActions';
//import FormikTextInput from 'components/inputs/FormikInputs';
import { updateForm } from 'actions/formActions';

const defaultValues = {
  OCPIConfigured: '',
  chargerOnlineCloud: '',
  chargerOnlineMobile: '',
  operationalCheck: {
    power: {
      bootNotification: {
        onCMS: '',
      },
      gunAvailableStatus: {
        onCharger: '',
        onCMS: '',
      },
    },
    remoteStart: {
      authorize: {
        onCMS: '',
      },
      status: {
        onCharger: '',
        onCMS: '',
      },
    },
    emergencyStop: {
      faultedStatus: {
        onCharger: '',
        onCMS: '',
      },
      stopReason: {
        onCMS: '',
      },
    },
    otpStart: {
      displayWorking: {
        onCharger: '',
      },
      authorize: {
        onCMS: '',
      },
      status: {
        onCharger: '',
        onCMS: '',
      },
    },
    powerFailure: {
      status: {
        onCharger: '',
        onCMS: '',
      },
      stopReason: {
        onCMS: '',
      },
    },
    rfid: {
      detection: {
        onCharger: '',
      },
    },
    doorOpen: {
      errorDisplay: {
        onCharger: '',
        onCMS: '',
      },
    },
    remoteStartStopWorking: '',
    allOperationWorkingForAllGuns: '',
  },
};
const CMSIntegration = ({ currentStep }) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(defaultValues);
  const { form } = useSelector((state) => state.forms);
  useEffect(() => {
    if (form !== null) {
      const { cmsIntegration = {} } = form;
      setInitialValues({
        OCPIConfigured: cmsIntegration?.OCPIConfigured || '',
        chargerOnlineCloud: cmsIntegration?.chargerOnlineCloud || '',
        chargerOnlineMobile: cmsIntegration?.chargerOnlineMobile || '',
        operationalCheck: {
          power: {
            bootNotification: {
              onCMS: cmsIntegration?.operationalCheck?.power?.bootNotification?.onCMS || '',
            },
            gunAvailableStatus: {
              onCharger: cmsIntegration?.operationalCheck?.power?.gunAvailableStatus?.onCharger || '',
              onCMS: cmsIntegration?.operationalCheck?.power?.gunAvailableStatus?.onCMS || '',
            },
          },
          remoteStart: {
            authorize: {
              onCMS: cmsIntegration?.operationalCheck?.remoteStart?.authorize?.onCMS || '',
            },
            status: {
              onCharger: cmsIntegration?.operationalCheck?.remoteStart?.status?.onCharger || '',
              onCMS: cmsIntegration?.operationalCheck?.remoteStart?.status?.onCMS || '',
            },
          },
          emergencyStop: {
            faultedStatus: {
              onCharger: cmsIntegration?.operationalCheck?.emergencyStop?.faultedStatus?.onCharger || '',
              onCMS: cmsIntegration?.operationalCheck?.emergencyStop?.faultedStatus?.onCMS || '',
            },
            stopReason: {
              onCMS: cmsIntegration?.operationalCheck?.emergencyStop?.stopReason?.onCMS || '',
            },
          },
          otpStart: {
            displayWorking: {
              onCharger: cmsIntegration?.operationalCheck?.otpStart?.displayWorking?.onCharger || '',
            },
            authorize: {
              onCMS: cmsIntegration?.operationalCheck?.otpStart?.authorize?.onCMS || '',
            },
            status: {
              onCharger: cmsIntegration?.operationalCheck?.otpStart?.status?.onCharger || '',
              onCMS: cmsIntegration?.operationalCheck?.otpStart?.status?.onCMS || '',
            },
          },
          powerFailure: {
            status: {
              onCharger: cmsIntegration?.operationalCheck?.powerFailure?.status?.onCharger || '',
              onCMS: cmsIntegration?.operationalCheck?.powerFailure?.status?.onCMS || '',
            },
            stopReason: {
              onCMS: cmsIntegration?.operationalCheck?.powerFailure?.stopReason?.onCMS || '',
            },
          },
          rfid: {
            detection: {
              onCharger: cmsIntegration?.operationalCheck?.rfid?.detection?.onCharger || '',
            },
          },
          doorOpen: {
            errorDisplay: {
              onCharger: cmsIntegration?.operationalCheck?.doorOpen?.errorDisplay?.onCharger || '',
              onCMS: cmsIntegration?.operationalCheck?.doorOpen?.errorDisplay?.onCMS || '',
            },
          },
          remoteStartStopWorking: cmsIntegration?.operationalCheck?.remoteStartStopWorking || '',
          allOperationWorkingForAllGuns: cmsIntegration?.operationalCheck?.allOperationWorkingForAllGuns || '',
        },
      });
    }
  }, [JSON.stringify(form)]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
        let body = {
          cmsIntegration: values,
        };
        body.step = (currentStep + 1).toString();
        dispatch(updateForm(form?.id, body));
      } else {
        dispatch(changeStep(0));
      }
    },
  });

  const options = [
    { label: 'YES', value: 'YES' },
    { label: 'NO', value: 'NO' },
    { label: 'NA', value: 'NA' },
  ];
  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="card my-4  ">
          <div className="table-responsive">
            <table className="table card-table align-middle  text-nowrap datatable  circuit ">
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <FormLabel>ChargeZone OCPI ID & URL configured in charge?</FormLabel>
                  </td>
                  <td>
                    <FormikDropDown formik={formik} className="text-input" name="OCPIConfigured" isRequired placeholder="Select" options={options} />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <FormLabel>Charger shows online with charge cloud on charger display?</FormLabel>
                  </td>
                  <td>
                    <FormikDropDown
                      formik={formik}
                      className="text-input"
                      name="chargerOnlineCloud"
                      isRequired
                      placeholder="Select"
                      options={options}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <FormLabel>Charger shown online on ChargeZone mobile app?</FormLabel>
                  </td>
                  <td>
                    <FormikDropDown
                      formik={formik}
                      className="text-input"
                      name="chargerOnlineMobile"
                      isRequired
                      placeholder="Select"
                      options={options}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table className="table card-table datatable  circuit operational">
              <thead>
                <tr>
                  <th rowSpan="2">Sr.No</th>
                  <th colSpan="1" rowSpan="2">
                    Operational Check Points
                  </th>
                  <th colSpan="2">On charger Display</th>
                  <th colSpan="2">On CMS</th>
                </tr>
                <tr>
                  <th>Actual</th>
                  <th>Expected</th>
                  <th>Actual</th>
                  <th>Expected</th>
                </tr>
                <tr>
                  <th>1</th>
                  <th colSpan="6">Power On the Charger</th>
                </tr>
              </thead>
              <tr>
                <td>a</td>
                <td>Boot Notification</td>
                <td>NA</td>
                <td>NA</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.power.bootNotification.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>b</td>
                <td>Available Status for each Gun - Status Notification Details</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.power.gunAvailableStatus.onCharger" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.power.gunAvailableStatus.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>

              <thead>
                <tr>
                  <th>2</th>
                  <th colSpan="6">Charge with OTP generated by CZ Mobile Application - Remote start from mobile application </th>
                </tr>
              </thead>

              <tr>
                <td>a</td>
                <td>Authorize </td>
                <td>NA</td>
                <td>NA</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.remoteStart.authorize.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>b</td>
                <td> Status during various stages of charging Preparing - Gun is connected to EV Finishing - When charging stops </td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.remoteStart.status.onCharger" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.remoteStart.status.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>
              <thead>
                <tr>
                  <th>3</th>
                  <th colSpan="6">Press Emergency Stop Button on the charger</th>
                </tr>
              </thead>
              <tr>
                <td>a</td>
                <td>Faulted status with relevant error message on charger display </td>
                <td>
                  <FormikDropDown
                    formik={formik}
                    name="operationalCheck.emergencyStop.faultedStatus.onCharger"
                    placeholder="Select"
                    options={options}
                  />
                </td>
                <td>YES</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.emergencyStop.faultedStatus.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>b</td>
                <td>Stop Reason as &apos;Emergency Stop &apos;in Stop Transaction</td>
                <td>NA</td>
                <td>NA</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.emergencyStop.stopReason.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>
              <thead>
                <tr>
                  <th>4</th>
                  <th colSpan="6">Charge with OTP generated by CZ Mobile Application - Enter OTP on the charger display</th>
                </tr>
              </thead>
              <tr>
                <td>a</td>
                <td>Charger display touch working, okay? </td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.otpStart.displayWorking.onCharger" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
                <td>NA</td>
                <td>NA</td>
              </tr>
              <tr>
                <td>b</td>
                <td>Authorize</td>
                <td>NA</td>
                <td>NA</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.otpStart.authorize.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>c</td>
                <td>
                  Status during various stages of charging preparing - Gun is connected to EV charging - During charging finishing - When charging
                  stops
                </td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.otpStart.status.onCharger" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.otpStart.status.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>
              <thead>
                <tr>
                  <th>5</th>
                  <th colSpan="6">Simulate Power Failure</th>
                </tr>
              </thead>
              <tr>
                <td>a</td>
                <td>Faulted status with relevant error message on charger display</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.powerFailure.status.onCharger" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.powerFailure.status.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>b</td>
                <td>Stop Reason as &apos;Power Failure&apos; in Stop Transaction</td>
                <td>NA</td>
                <td>NA</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.powerFailure.stopReason.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>
              <thead>
                <tr>
                  <th>6</th>
                  <th colSpan="6"> Start Charging using registered RFID card</th>
                </tr>
              </thead>
              <tr>
                <td>a</td>
                <td>RFID card is getting detected easily by the charge?</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.rfid.detection.onCharger" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
                <td>NA</td>
                <td>NA</td>
              </tr>
              <thead>
                <tr>
                  <th>7</th>
                  <th colSpan="6"> Simulate Door Open during charging</th>
                </tr>
              </thead>
              <tr>
                <td>a</td>
                <td>Faulted status with relevant error message on charger display</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.doorOpen.errorDisplay.onCharger" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
                <td>
                  <FormikDropDown formik={formik} name="operationalCheck.doorOpen.errorDisplay.onCMS" placeholder="Select" options={options} />
                </td>
                <td>YES</td>
              </tr>
              <thead>
                <tr>
                  <th>8</th>
                  <th colSpan="6"></th>
                </tr>
              </thead>
              <tr>
                <td colSpan="1">a</td>
                <td colSpan="3">Remote start-stop from mobile application working okay?</td>
                <td colSpan="2">
                  <FormikDropDown formik={formik} name="operationalCheck.remoteStartStopWorking" placeholder="Select" options={options} />
                </td>
              </tr>
              <thead>
                <tr>
                  <th>9</th>
                  <th colSpan="6"></th>
                </tr>
              </thead>
              <tr>
                <td colSpan="1">a</td>
                <td colSpan="3">All the above operations working okay with all the guns of charger?</td>
                <td colSpan="2">
                  <FormikDropDown formik={formik} name="operationalCheck.allOperationWorkingForAllGuns" placeholder="Select" options={options} />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="buttons">
          <button
            type="button"
            className="prev-btn"
            onClick={() => {
              dispatch(changeStep(currentStep - 1));
            }}
          >
            Previous
          </button>

          {form?.status !== 'approved' && form?.status !== 'pending' ? (
            <button type="submit" className="submit-btn">
              Next
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                dispatch(changeStep(currentStep + 1));
              }}
              className="submit-btn"
            >
              Next
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

CMSIntegration.propTypes = {
  currentStep: PropTypes.number,
};

export default CMSIntegration;
