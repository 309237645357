import React, { useEffect, useState } from 'react';
import Table from 'components/preform/Table';
import DeleteModal from 'components/general/DeleteModal';
// import Dropdown from 'components/inputs/DropDown';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_FORM, FETCH_FORMS } from 'actions/formActions';
import Pagination from 'components/general/Pagination';
import { Spinner } from 'react-bootstrap';

const AllForms = () => {
  const { forms, totalPages = '', totalResults = '', loading } = useSelector((state) => state.forms);
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState(null);
  const [filterSettings, setFilterSettings] = useState({
    page: 1,
    limit: 10,
    sortBy: '-createdAt',
  });
  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
  };
  // const options = [
  //   { label: 'All', value: '' },
  //   { label: 'Admin', value: 'admin' },
  //   { label: 'CE', value: 'engineer' },
  // ];

  useEffect(() => {
    fetchForms();
  }, [JSON.stringify(filterSettings)]);

  const fetchForms = () => {
    dispatch({
      type: FETCH_FORMS,
      payload: filterSettings,
    });
  };

  // const setRole = (option) => {
  //   if (option.value != '') {
  //     setFilterSettings((state) => ({ ...state, role: option.value }));
  //   } else {
  //     delete filterSettings.role;
  //     setFilterSettings(filterSettings);
  //   }
  // };

  const handleDeleteBtn = (formId) => {
    setDeleteFormId(formId);
    setShowDeleteModal(true);
  };

  const onRemove = () => {
    dispatch({ type: DELETE_FORM, payload: deleteFormId });
    setShowDeleteModal(false);
  };

  const changePage = ({ selected: pageIndex }) => {
    setFilterSettings((state) => ({ ...state, page: pageIndex + 1 }));
  };

  return (
    <>
      <div className="page-body">
        <div className="container-xl  new_application">
          <div className="row gap-2  align-items-center">
            <div className="col">
              <h2 className="page-title">All Forms</h2>
            </div>
            <div className="col-auto d-flex gap-4 ms-auto d-print-none">
              {/* <Dropdown label="Select" options={options} onChange={setRole} /> */}
              <div className="btn-list">
                <Link to="/allforms/form" className="btn btn-primary d-sm-inline-block">
                  New Application
                </Link>
              </div>
            </div>

            <div className="col-12 mt-2 mb-2">
              {loading ? (
                <div className="loader-container">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <Table data={forms} handleDeleteBtn={handleDeleteBtn} />
                  <Pagination
                    total={totalResults}
                    handlePageClick={changePage}
                    currentPage={filterSettings.page}
                    limit={filterSettings.limit}
                    totalPages={totalPages}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showDeleteModal && <DeleteModal show={showDeleteModal} title="Form" onClose={handleCloseDeleteModel} onRemove={onRemove} />}
    </>
  );
};
export default AllForms;
