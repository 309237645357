import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeStep, updateForm } from 'actions/formActions';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import FormikTextInput from 'components/inputs/FormikInputs';

const defaultValues = {
  manufacturerName: '',
  ACDBSerialNo: '',
  connectedChargers: '',
  installationDate: '',
};

const ACDBDetails = ({ currentStep }) => {
  const { form } = useSelector((state) => state.forms);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(defaultValues);

  useEffect(() => {
    if (form !== null) {
      setInitialValues({
        manufacturerName: form?.manufacturerName || '',
        ACDBSerialNo: form?.ACDBSerialNo || '',
        connectedChargers: form?.connectedChargers || '',
        installationDate: form?.installationDate || new Date().toISOString().slice(0, 10),
      });
    }
  }, [JSON.stringify(form)]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
        values.step = (currentStep + 1).toString();
        dispatch(updateForm(form?.id, values));
      } else {
        dispatch(changeStep(0));
      }
    },
  });
  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="row my-3 ">
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Manufacturer Name"
                placeholder="Enter Manufacturer Name"
                isRequired
                name="manufacturerName"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="ACDB Serial No."
                placeholder="Enter ACDB Serial No."
                isRequired
                name="ACDBSerialNo"
                formik={formik}
              />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Connected Chargers (In case of multiple chargers connected to one ACDB)"
                placeholder="Enter Connected Chargers"
                isRequired
                name="connectedChargers"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput type="date" className="text-input" label="Installation Date" isRequired name="installationDate" formik={formik} />
            </div>
          </div>
        </div>

        <div className="buttons">
          <button
            type="button"
            className="prev-btn"
            onClick={() => {
              dispatch(changeStep(currentStep - 1));
            }}
          >
            Previous
          </button>

          {form?.status !== 'approved' && form?.status !== 'pending' ? (
            <button type="submit" className="submit-btn">
              Next
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                dispatch(changeStep(currentStep + 1));
              }}
              className="submit-btn"
            >
              Next
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

ACDBDetails.propTypes = {
  currentStep: PropTypes.number,
};

export default ACDBDetails;
