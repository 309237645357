import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
  const {
    totalPages,
    handlePageClick,
    currentPage,
    limit,
    total,
    showDataCount = true, // Default to true if not explicitly passed
  } = props;

  let startItem = (currentPage - 1) * limit + 1;
  let endItem = startItem + limit - 1;

  if (startItem === 0) {
    startItem = 1;
  }

  if (endItem < limit) {
    endItem = limit;
  } else if (endItem > total) {
    endItem = total;
  }

  return (
    <div className="d-flex align-items-center">
      {showDataCount && (
        <p className="m-0 text-muted">
          Showing {startItem}-{endItem} of {total} entries
        </p>
      )}
      <ReactPaginate
        {...props}
        previousLabel={
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
            />
          </svg>
        }
        nextLabel={
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
            />
          </svg>
        }
        breakLabel={'..'}
        forcePage={currentPage - 1}
        breakClassName={'break-me'}
        pageCount={totalPages}
        onPageChange={handlePageClick}
        containerClassName={'pagination m-0 ms-auto'}
        activeClassName={'active'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        disabledLinkClassName={'disabled'}
      />
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  handlePageClick: PropTypes.func,
  currentPage: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  showDataCount: PropTypes.bool, // New prop to control the data count display
};

export default Pagination;
