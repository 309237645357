import React, { useEffect, useRef, useState } from 'react';
import Details from 'view/preForm/forms/details';
import ACDBDetails from 'view/preForm/forms/acdbDetails';
import TransportDetails from 'view/preForm/forms/transportDetails';
import Storage from 'view/preForm/forms/storage';
import DetailsPost from 'view/postForm/forms/details';
import ChargerInstallation from 'view/postForm/forms/chargerInstallation';
import InternetDetails from 'view/postForm/forms/internetDetails';
import TextareaField from 'components/inputs/TextareaField';
import CircuitMeasurements from 'view/postForm/forms/circuitMeasurements';
import CMSIntegration from 'view/postForm/forms/cmsIntegration';
import ChargerEfficiency from 'view/postForm/forms/chargerEfficiency';
import Contact from 'view/postForm/forms/contact';
import SignUp from 'view/postForm/forms/signUp';
import { useDispatch, useSelector } from 'react-redux';
import { changeStep, getForm, unsetForm, updateForm } from 'actions/formActions';
import { useNavigate, useParams } from 'react-router-dom';
import { FormLabel } from 'react-bootstrap';

const PreFormSteps = [
  {
    name: 'General Details',
  },
  {
    name: 'ACDB Details',
  },
  {
    name: 'Transportation Details',
  },
  {
    name: 'Storage',
  },
];

const PostFormSteps = [
  {
    name: 'Electricity Connection Details',
  },
  {
    name: 'Charger Installation',
  },
  {
    name: 'Electrical Measurements',
  },
  {
    name: 'Internet Details',
  },
  {
    name: 'CMS Integration Status & Functional Check Points',
  },
  {
    name: 'Charger Efficiency',
  },
  {
    name: 'Person of Contact',
  },
  {
    name: 'Sign Off',
  },
];

function _renderFormStep(step) {
  switch (step) {
    case 0:
      return <Details currentStep={step} />;
    case 1:
      return <ACDBDetails currentStep={step} />;
    case 2:
      return <TransportDetails currentStep={step} />;
    case 3:
      return <Storage currentStep={step} />;
    case 4:
      return <DetailsPost currentStep={step} />;
    case 5:
      return <ChargerInstallation currentStep={step} />;
    case 6:
      return <CircuitMeasurements currentStep={step} />;
    case 7:
      return <InternetDetails currentStep={step} />;
    case 8:
      return <CMSIntegration currentStep={step} />;
    case 9:
      return <ChargerEfficiency currentStep={step} />;
    case 10:
      return <Contact currentStep={step} />;
    case 11:
      return <SignUp currentStep={step} />;
    default:
      return null;
  }
}

const Form = () => {
  const { formId = '' } = useParams();
  const activeRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentStep, form } = useSelector((state) => state.forms);
  const {
    user: { user },
  } = useSelector((state) => state.auth);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (formId !== '') {
      dispatch(getForm(formId));
    }
    return () => {
      dispatch(unsetForm());
    };
  }, [formId]);

  const submitForm = async (status = false) => {
    let body = { status: 'rework' };
    if (status === true) {
      body['status'] = 'approved';
    }
    if (description !== '') {
      body['approverComment'] = description;
    }
    await Promise.resolve(dispatch(updateForm(formId, body)));
    navigate('/allforms');
  };

  useEffect(() => {
    if (activeRef.current) {
      activeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [currentStep]);

  const changeStepFn = (value) => {
    if (form?.status !== ('draft' && undefined)) {
      dispatch(changeStep(value));
    }
  };

  return (
    <>
      <div className="page-body">
        <div className="preform">
          <div className="container-xl p-3">
            <div className="nav-tabs">
              <div className="d-flex justify-content-between align-items-center">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className={`nav-link ${currentStep < 4 ? 'active' : ''}`}>Pre Form</a>
                  </li>
                  <li className="nav-item">
                    <a className={`nav-link ${currentStep > 3 ? 'active' : ''}`}>Post Form</a>
                  </li>
                </ul>
                {form?.status === 'rework' && user.role === 'engineer' && (
                  <div className="viewcomments" data-bs-toggle="modal" data-bs-target="#modal-report-pre">
                    View Comments
                  </div>
                )}
              </div>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="pre-form">
                <div className="divider"></div>
                <div className="preform-header py-3 gap-5 d-flex">
                  {currentStep < 4
                    ? PreFormSteps.map((s, index) => (
                        <span
                          key={s.name}
                          ref={index === currentStep ? activeRef : null}
                          className={index === currentStep ? 'active' : 'inactive'}
                          onClick={() => changeStepFn(index)}
                        >
                          {s.name}
                        </span>
                      ))
                    : PostFormSteps.map((s, index) => (
                        <span
                          key={s.name}
                          ref={index + 4 === currentStep ? activeRef : null}
                          className={index + 4 === currentStep ? 'active' : 'inactive'}
                          onClick={() => changeStepFn(index + 4)}
                        >
                          {s.name}
                        </span>
                      ))}
                </div>
                {_renderFormStep(currentStep)}

                {/* {form?.status === 'pending' && form?.reportingTo === user?.id && user.role === 'admin' && ( */}
                {form?.status === 'pending' && user.role === 'admin' && (
                  <>
                    <div className="row my-3 align-items-end">
                      <div className="col-12">
                        <FormLabel>Managers Comments</FormLabel>
                        <TextareaField value={description || form.approverComment || ''} onChange={(e) => setDescription(e.target.value)} rows={5} />
                      </div>
                    </div>
                    <div className="buttons">
                      <button
                        type="button"
                        className="prev-btn"
                        onClick={() => {
                          submitForm(false);
                        }}
                      >
                        Reject
                      </button>

                      <button
                        type="submit"
                        className="submit-btn"
                        onClick={() => {
                          submitForm(true);
                        }}
                      >
                        Approve
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal modal-blur fade" id="modal-report-pre" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Managers Comments</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                {form?.status === 'rework' && user.role === 'engineer' && (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <TextareaField
                          value={form.approverComment}
                          onChange={(e) => setDescription(e.target.value)}
                          rows={12}
                          readonly
                          className="form-control"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
