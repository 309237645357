import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import PropTypes from 'prop-types';
import { IconEraser } from '@tabler/icons-react';
import { Button, Modal } from 'react-bootstrap';
import { handleImage } from 'helper/helper';
const DigitalSignature = ({ value, onChange, show, onHide }) => {
  const [signature, setSignature] = useState(value); // Initialize signature with the provided value
  const signatureRef = useRef(null);

  const handleClear = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      setSignature(null);
      onChange(null);
    }
  };
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  const handleSave = async () => {
    if (signatureRef.current) {
      const savedSignature = signatureRef.current.toDataURL('image/png');
      setSignature(savedSignature);
      const blob = new Blob([dataURItoBlob(savedSignature)], { type: 'image/png' });
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      const uploadedSignature = await handleImage(file, 'digital_signature');

      onChange(uploadedSignature);
      onHide();
    }
  };
  useEffect(() => {
    setSignature(value); // Update the signature when the value prop changes
  }, [value]);
  useEffect(() => {
    const resizeCanvas = () => {
      if (signatureRef.current) {
        const canvas = signatureRef.current.getCanvas();
        if (canvas) {
          canvas.style.width = '100%'; // Set the canvas width to 100% of its container
          const ratio = Math.max(window.devicePixelRatio || 1, 1);
          canvas.width = canvas.offsetWidth * ratio;
          canvas.height = canvas.width * 0.75;
          canvas.getContext('2d').scale(ratio, ratio);
          signatureRef.current.clear(); // otherwise isEmpty() might return incorrect value
        }
      }
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    return () => {
      window.removeEventListener('resize', resizeCanvas); // Clean up the event listener
    };
  }, [signature]);
  return (
    <Modal show={show} onHide={onHide} centered size="sm">
      <div className="modal-content">
        <div className="modal-body">
          <div className="modal-title text-center">Your Digital Signature</div>
          {signature ? (
            <img src={signature} alt="Saved Signature" />
          ) : (
            <div style={{ border: '2px solid #1abc9c' }}>
              <SignatureCanvas backgroundColor="#fff" penColor="#1abc9c" canvasProps={{ height: 100, className: 'sigCanvas' }} ref={signatureRef} />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <IconEraser onClick={handleClear} />
          <Button className="submit-btn" data-bs-dismiss="modal" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DigitalSignature.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};

export default DigitalSignature;
