import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  ADD_FORM,
  ADD_FORM_ERROR,
  ADD_FORM_SUCCESS,
  DELETE_FORM,
  DELETE_FORM_ERROR,
  DELETE_FORM_SUCCESS,
  FETCH_FORM,
  FETCH_FORM_FAIL,
  FETCH_FORM_SUCCESS,
  FETCH_CHARGERS,
  FETCH_CHARGERS_FAIL,
  FETCH_CHARGERS_SUCCESS,
  FETCH_FORMS,
  FETCH_FORMS_FAIL,
  FETCH_FORMS_SUCCESS,
  UPDATE_FORM,
  UPDATE_FORM_ERROR,
  UPDATE_FORM_SUCCESS,
  ASSIGN_CHARGER,
  ASSIGN_CHARGER_SUCCESS,
  ASSIGN_CHARGER_FAIL,
} from 'actions/formActions';

function* handleAddForm(action) {
  try {
    const response = yield call(API.addForm, action.payload);
    yield put({ type: ADD_FORM_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: ADD_FORM_ERROR, payload: { error: e } });
  }
}

function* handleAssignCharger(action) {
  try {
    const response = yield call(API.assignCharger, action.payload);
    yield put({ type: ASSIGN_CHARGER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: ASSIGN_CHARGER_FAIL, payload: { error: e } });
  }
}

function* handleFetchChargers() {
  try {
    const response = yield call(API.fetchChargers);
    yield put({ type: FETCH_CHARGERS_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CHARGERS_FAIL, payload: { error: e } });
  }
}

function* handleFetchForms(action) {
  try {
    const response = yield call(API.fetchForms, action.payload);
    yield put({ type: FETCH_FORMS_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_FORMS_FAIL, payload: { error: e } });
  }
}

function* handleFetchForm(action) {
  try {
    const response = yield call(API.fetchForm, action.payload);
    yield put({ type: FETCH_FORM_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_FORM_FAIL, payload: { error: e } });
  }
}

function* handleUpdateForm(action) {
  try {
    const response = yield call(API.updateForm, action.payload);
    yield put({ type: UPDATE_FORM_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: UPDATE_FORM_ERROR, payload: { error: e } });
  }
}

function* handleDeleteForm(action) {
  try {
    yield call(API.deleteForm, action.payload);
    yield put({ type: DELETE_FORM_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_FORM_ERROR, payload: { error: e } });
  }
}

function* form() {
  yield takeLatest(ADD_FORM, handleAddForm);
  yield takeLatest(ASSIGN_CHARGER, handleAssignCharger);
  yield takeLatest(FETCH_CHARGERS, handleFetchChargers);
  yield takeLatest(FETCH_FORMS, handleFetchForms);
  yield takeLatest(FETCH_FORM, handleFetchForm);
  yield takeLatest(UPDATE_FORM, handleUpdateForm);
  yield takeLatest(DELETE_FORM, handleDeleteForm);
}
export default form;
