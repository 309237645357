import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const ConfirmationModal = ({ show, onHide, onConfirm, title, message }) => {
  return (
    <React.Fragment>
      <Modal show={show} onHide={onHide} centered size="sm">
        <div className="modal-content">
          <div className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHide}></div>
          <div className="modal-body text-center py-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon mb-2 text-danger icon-lg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z" />
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
            </svg>
            <h3>{title}</h3>
            <div className="text-muted">{message}</div>
          </div>
          <div className="modal-footer">
            <div className="w-100">
              <div className="row">
                <div className="col">
                  <Link onClick={onHide} className="btn w-100" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                </div>
                <div className="col">
                  <Link onClick={onConfirm} className="btn btn-danger w-100">
                    Confirm
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
export default ConfirmationModal;
