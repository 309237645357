import React, { useEffect, useState } from 'react';
import Table from 'components/usermanagement/Table';
import DeleteModal from 'components/general/DeleteModal';
import Dropdown from 'components/inputs/DropDown';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_USER, FETCH_USERS } from 'actions/userActions';
import Pagination from 'components/general/Pagination';
import { Spinner } from 'react-bootstrap';
const UserManagement = () => {
  const { users, totalPages = '', totalResults = '', loading } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [filterSettings, setFilterSettings] = useState({
    page: 1,
    limit: 10,
    sortBy: '-createdAt',
  });
  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
  };
  const options = [
    { label: 'All', value: '' },
    { label: 'Admin', value: 'admin' },
    { label: 'CE', value: 'engineer' },
  ];

  useEffect(() => {
    fetchUsers();
  }, [JSON.stringify(filterSettings)]);

  const fetchUsers = () => {
    const params = {
      ...filterSettings,
      ...(filterSettings.role && { role: filterSettings.role }),
    };

    dispatch({
      type: FETCH_USERS,
      payload: params,
    });
  };

  const setRole = (option) => {
    setFilterSettings((prev) => ({
      ...prev,
      role: option.value === '' ? undefined : option.value,
    }));
  };

  const handleDeleteBtn = (userId) => {
    setDeleteUserId(userId);
    setShowDeleteModal(true);
  };

  const onRemove = () => {
    dispatch({ type: DELETE_USER, payload: deleteUserId });
    setShowDeleteModal(false);
  };

  const changePage = ({ selected: pageIndex }) => {
    setFilterSettings((state) => ({ ...state, page: pageIndex + 1 }));
  };

  return (
    <>
      <div className="page-body">
        <div className="container-xl  new_application">
          <div className="row gap-2  align-items-center">
            <div className="col">
              <h2 className="page-title">All Users</h2>
            </div>
            <div className="col-auto d-flex gap-4 ms-auto d-print-none">
              <Dropdown label="Select" options={options} onChange={setRole} />
              <div className="btn-list">
                <Link to="/usermanagement/add" className="btn btn-primary d-sm-inline-block">
                  Create New
                </Link>
              </div>
            </div>

            <div className="col-12 mt-2 mb-2">
              {loading ? (
                <div className="loader-container">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <Table data={users} handleDeleteBtn={handleDeleteBtn} />
                  <Pagination
                    total={totalResults}
                    handlePageClick={changePage}
                    currentPage={filterSettings.page}
                    limit={filterSettings.limit}
                    totalPages={totalPages}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showDeleteModal && <DeleteModal show={showDeleteModal} title="User" onClose={handleCloseDeleteModel} onRemove={onRemove} />}
    </>
  );
};
export default UserManagement;
