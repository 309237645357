import React, { useEffect, useState } from 'react';
// import CheckBox from 'components/inputs/CheckBox';
import { FormLabel } from 'react-bootstrap';
import RadioButton from 'components/inputs/RadioButton';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { changeStep, updateForm } from 'actions/formActions';
import { useFormik } from 'formik';
import FormikTextInput from 'components/inputs/FormikInputs';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from 'components/general/ConfirmationModal ';
import DigitalSignature from 'components/general/DigitalSignature';
import TextInput from 'components/inputs/Inputs';
import { handleImage } from 'helper/helper';
import TextareaField from 'components/inputs/TextareaField';

const defaultValues = {
  commissionEngineer: {
    chargeZone: '',
    oem: '',
    client: '',
  },
  date: {
    chargeZone: '',
    oem: '',
    client: '',
  },
  sign: {
    chargeZone: '',
    oem: '',
    client: '',
  },
  assigneeComment: '',
};
const SignUp = ({ currentStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [signType, setSignType] = useState({
    chargeZone: 'image',
    oem: 'image',
    client: 'image',
  });
  const [digitalSignatures, setDigitalSignatures] = useState({});
  const [sign, setSign] = useState({
    chargeZone: null,
    oem: null,
    client: null,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState({
    visible: false,
    data: null,
  });
  const { form } = useSelector((state) => state.forms);
  const {
    user: { user },
  } = useSelector((state) => state.auth);

  const [isUpdating, setIsUpdating] = useState(false);
  const [imagePreview, setImagePreview] = useState({
    chargeZone: null,
    oem: null,
    client: null,
  });

  useEffect(() => {
    if (form !== null) {
      const { signUp = {}, assigneeComment } = form;
      setInitialValues({
        commissionEngineer: {
          chargeZone: signUp?.commissionEngineer?.chargeZone || '',
          oem: signUp?.commissionEngineer?.oem || '',
          client: signUp?.commissionEngineer?.client || '',
        },
        date: {
          chargeZone: signUp?.date?.chargeZone || new Date().toISOString().slice(0, 10),
          oem: signUp?.date?.oem || new Date().toISOString().slice(0, 10),
          client: signUp?.date?.client || new Date().toISOString().slice(0, 10),
        },
        sign: {
          chargeZone: signUp?.sign?.chargeZone || '',
          oem: signUp?.sign?.oem || '',
          client: signUp?.sign?.client || '',
        },
        assigneeComment: assigneeComment || '',
      });
    }
    if (form.status === 'approved' || form.status === 'pending') {
      setIsUpdating(true);
    } else {
      setIsUpdating(false);
    }
  }, [JSON.stringify(form)]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
        let { assigneeComment, ...rest } = values;
        let body = {
          signUp: rest,
          assigneeComment,
        };
        if (user.role === 'admin') {
          body.status = 'approved';
        } else {
          body.status = 'pending';
        }

        setShowConfirmationModal({ visible: true, data: body });
      } else {
        dispatch(changeStep(0));
      }
    },
  });
  const handleSignTypeChange = (type, column) => {
    setSignType((prevState) => ({
      ...prevState,
      [column]: type,
    }));
  };

  const handleSignatureChange = (signature, column) => {
    setDigitalSignatures((prev) => ({
      ...prev,
      [column]: signature,
    }));
    //formik.setFieldValue(`signUp.digitalSign.${column}`, signature);
  };

  const handleConfirmation = async () => {
    let chargeZoneSignature = '';
    let oemSignature = '';
    let clientSignature = '';

    if (form.status === 'rework') {
      // Check if the user has changed the value for sign
      if (signType.chargeZone === 'digital') {
        chargeZoneSignature = digitalSignatures.chargeZone;
      } else {
        if (sign.chargeZone && sign.chargeZone !== form.signUp?.sign?.chargeZone) {
          chargeZoneSignature = await handleImage(sign.chargeZone, `${form.id}_sign_chargezone`);
        } else {
          chargeZoneSignature = form.signUp?.sign?.chargeZone;
        }
      }

      if (signType.oem === 'digital') {
        oemSignature = digitalSignatures.oem;
      } else {
        if (sign.oem && sign.oem !== form.signUp?.sign?.oem) {
          oemSignature = await handleImage(sign.oem, `${form.id}_sign_oem`);
        } else {
          oemSignature = form.signUp?.sign?.oem;
        }
      }

      if (signType.client === 'digital') {
        clientSignature = digitalSignatures.client;
      } else {
        if (sign.client && sign.client !== form.signUp?.sign?.client) {
          clientSignature = await handleImage(sign.client, `${form.id}_sign_client`);
        } else {
          clientSignature = form.signUp?.sign?.client;
        }
      }
    } else {
      // For other statuses, follow the previous logic
      chargeZoneSignature =
        signType.chargeZone === 'digital' ? digitalSignatures.chargeZone : await handleImage(sign.chargeZone, `${form.id}_sign_chargezone`);
      oemSignature = signType.oem === 'digital' ? digitalSignatures.oem : await handleImage(sign.oem, `${form.id}_sign_oem`);
      clientSignature = signType.client === 'digital' ? digitalSignatures.client : await handleImage(sign.client, `${form.id}_sign_client`);
    }

    const body = showConfirmationModal.data;
    body.signUp.sign.chargeZone = chargeZoneSignature;
    body.signUp.sign.oem = oemSignature;
    body.signUp.sign.client = clientSignature;

    await Promise.resolve(dispatch(updateForm(form?.id, body)));
    setShowConfirmationModal({ visible: false, data: null });
    navigate('/allforms');
  };
  const [showSignaturePopup, setShowSignaturePopup] = useState(false);
  const handleSignaturePopupClose = () => {
    setShowSignaturePopup(false);
  };

  const handleOpenSignaturePopup = () => {
    setShowSignaturePopup(true);
  };

  return (
    <>
      <div className="card-body">
        <form onSubmit={formik.handleSubmit}>
          <div className="card my-4 ">
            <div className="table-responsive">
              <table className="table card-table  datatable  circuit ">
                <thead className="text-center">
                  <tr>
                    <th scope="colgroup"></th>
                    <th colSpan="1" scope="colgroup">
                      ChargeZone
                    </th>
                    <th colSpan="1" scope="colgroup">
                      OEM
                    </th>
                    <th colSpan="1" scope="colgroup">
                      Client
                    </th>
                  </tr>
                </thead>
                <tr>
                  <td>Commissioning Engineer Name</td>
                  <td>
                    <FormikTextInput
                      type="text"
                      placeholder="Enter Engineer Name"
                      className="text-input"
                      isRequired
                      name="commissionEngineer.chargeZone"
                      formik={formik}
                    />
                  </td>
                  <td>
                    <FormikTextInput
                      type="text"
                      placeholder="Enter Engineer Name"
                      className="text-input"
                      isRequired
                      name="commissionEngineer.oem"
                      formik={formik}
                    />
                  </td>
                  <td>
                    <FormikTextInput
                      type="text"
                      placeholder="Enter Client Name"
                      className="text-input"
                      isRequired
                      name="commissionEngineer.client"
                      formik={formik}
                    />
                  </td>
                </tr>
                <tr className="signature">
                  <td>Sign</td>
                  <td>
                    {isUpdating ? (
                      <>{form.signUp?.sign?.chargeZone ? <img src={form.signUp?.sign?.chargeZone} alt="ChargeZone Signature" height={80} /> : null}</>
                    ) : (
                      <>
                        <div className="storage-item d-flex gap-2 align-items-center">
                          <div className=" d-flex gap-3">
                            <RadioButton
                              type="radio"
                              value="image"
                              checked={signType.chargeZone === 'image'}
                              onChange={() => handleSignTypeChange('image', 'chargeZone')}
                            >
                              Image
                            </RadioButton>

                            <RadioButton
                              type="radio"
                              value="digital"
                              checked={signType.chargeZone === 'digital'}
                              onChange={() => handleSignTypeChange('digital', 'chargeZone')}
                              onClick={handleOpenSignaturePopup}
                            >
                              Digital
                            </RadioButton>
                          </div>
                        </div>
                        <div className="sign">
                          {signType.chargeZone === 'image' && (
                            <>
                              <TextInput
                                type="file"
                                name="sign.chargeZone"
                                className="text-input"
                                accept="image/*"
                                onChange={(e) => {
                                  const file = e.currentTarget.files[0];
                                  setSign({ ...sign, chargeZone: file });
                                  if (file) {
                                    const imageUrl = URL.createObjectURL(file);
                                    setImagePreview({ ...imagePreview, chargeZone: imageUrl });
                                  } else {
                                    setImagePreview({ ...imagePreview, chargeZone: null });
                                  }
                                }}
                                formik={formik}
                              />
                              {imagePreview.chargeZone ? (
                                <img src={imagePreview.chargeZone} alt="Preview" height={80} />
                              ) : form.signUp?.sign?.chargeZone ? (
                                <img src={form.signUp?.sign?.chargeZone} alt="ChargeZone Signature" height={80} />
                              ) : null}
                            </>
                          )}
                          {signType.chargeZone === 'digital' && digitalSignatures.chargeZone && (
                            <img
                              name="sign.chargeZone"
                              src={digitalSignatures.chargeZone}
                              alt="ChargeZone Signature"
                              onChange={(e) => setSign({ ...sign, chargeZone: e.currentTarget.files[0] })}
                              height={80}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </td>
                  <td>
                    {isUpdating ? (
                      <>{form.signUp?.sign?.oem ? <img src={form.signUp?.sign?.oem} alt="OEM Signature" height={80} /> : null}</>
                    ) : (
                      <>
                        <div className="storage-item d-flex gap-2 align-items-center">
                          <div className=" d-flex gap-3">
                            <RadioButton
                              type="radio"
                              value="image"
                              checked={signType.oem === 'image'}
                              onChange={() => handleSignTypeChange('image', 'oem')}
                            >
                              Image
                            </RadioButton>

                            <RadioButton
                              type="radio"
                              value="digital"
                              checked={signType.oem === 'digital'}
                              onChange={() => handleSignTypeChange('digital', 'oem')}
                              onClick={handleOpenSignaturePopup}
                            >
                              Digital
                            </RadioButton>
                          </div>
                        </div>
                        <div className="sign">
                          {signType.oem === 'image' && (
                            <>
                              <TextInput
                                type="file"
                                name="sign.oem"
                                className="text-input"
                                accept="image/*"
                                onChange={(e) => {
                                  const file = e.currentTarget.files[0];
                                  setSign({ ...sign, oem: file });
                                  if (file) {
                                    const imageUrl = URL.createObjectURL(file);
                                    setImagePreview({ ...imagePreview, oem: imageUrl });
                                  } else {
                                    setImagePreview({ ...imagePreview, oem: null });
                                  }
                                }}
                                formik={formik}
                              />
                              {imagePreview.oem ? (
                                <img src={imagePreview.oem} alt="Preview" height={80} />
                              ) : form.signUp?.sign?.oem ? (
                                <img src={form.signUp?.sign?.oem} alt="OEM Signature" height={80} />
                              ) : null}
                            </>
                          )}
                          {signType.oem === 'digital' && digitalSignatures.oem && (
                            <img
                              name="sign.oem"
                              src={digitalSignatures.oem}
                              alt="OEM Signature"
                              onChange={(e) => setSign({ ...sign, oem: e.currentTarget.files[0] })}
                              height={80}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </td>
                  <td>
                    {isUpdating ? (
                      <>{form.signUp?.sign?.client ? <img src={form.signUp?.sign?.client} alt="Client Signature" height={80} /> : null}</>
                    ) : (
                      <>
                        <div className="storage-item d-flex gap-2 align-items-center">
                          <div className=" d-flex gap-3">
                            <RadioButton
                              type="radio"
                              value="image"
                              checked={signType.client === 'image'}
                              onChange={() => handleSignTypeChange('image', 'client')}
                            >
                              Image
                            </RadioButton>

                            <RadioButton
                              type="radio"
                              value="digital"
                              checked={signType.client === 'digital'}
                              onChange={() => handleSignTypeChange('digital', 'client')}
                              onClick={handleOpenSignaturePopup}
                            >
                              Digital
                            </RadioButton>
                          </div>
                        </div>
                        <div className="sign">
                          {signType.client === 'image' && (
                            <>
                              <TextInput
                                type="file"
                                name="sign.client"
                                className="text-input"
                                accept="image/*"
                                onChange={(e) => {
                                  const file = e.currentTarget.files[0];
                                  setSign({ ...sign, client: file });
                                  if (file) {
                                    const imageUrl = URL.createObjectURL(file);
                                    setImagePreview({ ...imagePreview, client: imageUrl });
                                  } else {
                                    setImagePreview({ ...imagePreview, client: null });
                                  }
                                }}
                                formik={formik}
                              />
                              {imagePreview.client ? (
                                <img src={imagePreview.client} alt="Preview" height={80} />
                              ) : form.signUp?.sign?.client ? (
                                <img src={form.signUp?.sign?.client} alt="Client Signature" height={80} />
                              ) : null}
                            </>
                          )}
                          {signType.client === 'digital' && digitalSignatures.client && (
                            <img
                              name="sign.client"
                              src={digitalSignatures.client}
                              alt="Client Signature"
                              onChange={(e) => setSign({ ...sign, client: e.currentTarget.files[0] })}
                              height={80}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>
                    <FormikTextInput type="date" className="text-input" isRequired name="date.chargeZone" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="date" className="text-input" isRequired name="date.oem" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="date" className="text-input" isRequired name="date.client" formik={formik} />
                  </td>
                </tr>
              </table>
            </div>
            <div className="row m-3 align-items-end">
              <div className="col-12">
                <FormLabel>Assignee Comments</FormLabel>
                <TextareaField
                  rows={5}
                  name="assigneeComment"
                  placeholder="Enter Assignee Comments"
                  onChange={(e) => {
                    let value = e.target.value;
                    formik.setFieldValue('assigneeComment', value);
                  }}
                  className="assigneeComment"
                  value={formik.values.assigneeComment}
                  readonly={false}
                />
              </div>
            </div>
          </div>
          <div className="buttons">
            <button
              type="button"
              className="prev-btn"
              onClick={() => {
                dispatch(changeStep(currentStep - 1));
              }}
            >
              Previous
            </button>

            {form?.status !== 'approved' && form?.status !== 'pending' && (
              <button type="submit" className="submit-btn">
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
      <ConfirmationModal
        show={showConfirmationModal.visible}
        onHide={() => setShowConfirmationModal({ visible: false, data: null })}
        onConfirm={handleConfirmation}
        title="Confirm Submission"
        message="Are you sure you want to submit the form?"
      />
      {signType.chargeZone === 'digital' && (
        <DigitalSignature
          name="sign.chargeZone"
          value={digitalSignatures.chargeZone}
          onChange={(signature) => handleSignatureChange(signature, 'chargeZone')}
          show={showSignaturePopup}
          onHide={handleSignaturePopupClose}
        />
      )}
      {signType.oem === 'digital' && (
        <DigitalSignature
          name="sign.oem"
          value={digitalSignatures.oem}
          onChange={(signature) => handleSignatureChange(signature, 'oem')}
          show={showSignaturePopup}
          onHide={handleSignaturePopupClose}
        />
      )}
      {signType.client === 'digital' && (
        <DigitalSignature
          name="sign.client"
          value={digitalSignatures.client}
          onChange={(signature) => handleSignatureChange(signature, 'client')}
          show={showSignaturePopup}
          onHide={handleSignaturePopupClose}
        />
      )}
    </>
  );
};

SignUp.propTypes = {
  currentStep: PropTypes.number,
};

export default SignUp;
