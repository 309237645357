import React, { useState } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';
import { SIGN_IN_REQUEST } from 'actions/authActions';
import withAuth from '../../hoc/withAuth';
import TextInput from 'components/inputs/Inputs';
const NewPassword = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <>
      <div className="d-flex flex-column login-page">
        <div className="page page-center">
          <div className="container container-tight py-4">
            <div className="card card-sm">
              <div className="text-center">
                <Link to="/" className="navbar-brand navbar-brand-autodark">
                  <h2 className="new-password">Create New Password</h2>
                </Link>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={{ password: '', confirm_password: '' }}
                  onSubmit={(values, { setSubmitting }) => {
                    dispatch({ type: SIGN_IN_REQUEST, payload: values });
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <div className="input-group input-group-flat">
                            <TextInput
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              autoComplete="off"
                              size="md"
                              placeholder="Password"
                            />
                            <div className="error-message">{errors.password && touched.password && errors.password}</div>
                            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <IconEye /> : <IconEyeOff />}
                            </span>
                          </div>
                        </div>
                        <div className="mb-2">
                          <div className="input-group input-group-flat">
                            <TextInput
                              type={showConfirmPassword ? 'text' : 'password'}
                              name="confirm_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirm_password}
                              autoComplete="off"
                              size="md"
                              placeholder="Confirm Password"
                            />
                            <div className="error-message">
                              {errors.confirm_password && touched.confirm_password ? errors.confirm_password : null}
                            </div>
                            <span className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                              {showConfirmPassword ? <IconEye /> : <IconEyeOff />}
                            </span>
                          </div>
                        </div>

                        <div className="form-footer">
                          <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>
                            Create New Password
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(NewPassword);
