import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const DeleteModal = (props) => {
  const { title = '', onClose = _.noop(), onRemove = _.noop() } = props;
  return (
    <React.Fragment>
      <Modal show={props.show} centered size="sm">
        <div className="modal-content">
          <div className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}></div>
          <div className="modal-body text-center py-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon mb-2 text-danger icon-lg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z" />
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
            </svg>
            <h3>Are you sure?</h3>
            <div className="text-muted">Do you really want to remove {_.toLower(title)}? What you&apos;ve done cannot be undone.</div>
          </div>
          <div className="modal-footer">
            <div className="w-100">
              <div className="row">
                <div className="col">
                  <Link onClick={onClose} className="btn w-100" data-bs-dismiss="modal">
                    Cancel
                  </Link>
                </div>
                <div className="col">
                  <Link onClick={onRemove} className="btn btn-danger w-100" data-bs-dismiss="modal">
                    Delete {title}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
DeleteModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
};
export default DeleteModal;
