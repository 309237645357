import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import RadioButton from 'components/inputs/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { changeStep, updateForm } from 'actions/formActions';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import FormikTextInput from 'components/inputs/FormikInputs';

const defaultValues = {
  chargerArrivalDate: '',
  ACDBArrivalDate: '',
  nameOfTransporter: '',
  consigneeName: '',
  consigneeContact: '',
  missingItems: '',
};

const TransportDetails = ({ currentStep }) => {
  const [checked, setChecked] = useState('no');
  const [initialValues, setInitialValues] = useState(defaultValues);
  const { form } = useSelector((state) => state.forms);
  const dispatch = useDispatch();

  useEffect(() => {
    if (form !== null) {
      setInitialValues({
        chargerArrivalDate: form?.chargerArrivalDate || new Date().toISOString().slice(0, 10),
        ACDBArrivalDate: form?.ACDBArrivalDate || new Date().toISOString().slice(0, 10),
        nameOfTransporter: form?.nameOfTransporter || '',
        consigneeName: form?.consigneeName || '',
        consigneeContact: form?.consigneeContact || '',
        missingItems: form?.missingItems || '',
      });
      if (form?.anyDamage) {
        setChecked(form?.anyDamage);
      }
    }
  }, [JSON.stringify(form)]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
        values.step = (currentStep + 1).toString();
        values.anyDamage = checked;
        dispatch(updateForm(form?.id, values));
      } else {
        dispatch(changeStep(0));
      }
    },
  });

  const handleChange = (e) => {
    setChecked(e.target.value);
  };

  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="row my-3">
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="date"
                className="text-input"
                label="Charger Arrival Date at Site"
                isRequired
                name="chargerArrivalDate"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="date"
                className="text-input"
                label="ACDB Arrival Date at Site"
                isRequired
                name="ACDBArrivalDate"
                formik={formik}
              />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-4">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Transporter Name"
                placeholder="Enter Transporter Name"
                isRequired
                name="nameOfTransporter"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Consignee Name"
                placeholder="Enter Consignee Name"
                isRequired
                name="consigneeName"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Consignee Contact Number"
                placeholder="Enter Consignee Contact Number"
                isRequired
                name="consigneeContact"
                formik={formik}
              />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12">
            <div className="mb-3">
              <div className="storage-item d-flex gap-2 align-items-center">
                <FormLabel htmlFor="storage" className="form-label">
                  Any Damaged / Missing Item?
                </FormLabel>

                <div className=" d-flex gap-3">
                  <RadioButton id="anyDamage_yes" containerClassName="radio-field" value="yes" checked={checked === 'yes'} onChange={handleChange}>
                    Yes
                  </RadioButton>
                  <RadioButton id="anyDamage_no" containerClassName="radio-field" value="no" checked={checked === 'no'} onChange={handleChange}>
                    No
                  </RadioButton>
                </div>
              </div>
            </div>
          </div>
          {checked === 'yes' && (
            <div className="col-6">
              <div className="mb-3">
                <FormikTextInput
                  type="text"
                  className="text-input"
                  label="List of Damaged / Missing Items"
                  name="missingItems"
                  placeholder="Enter List of Damaged / Missing Items"
                  formik={formik}
                />
              </div>
            </div>
          )}
        </div>
        <div className="buttons">
          <button
            type="button"
            className="prev-btn"
            onClick={() => {
              dispatch(changeStep(currentStep - 1));
            }}
          >
            Previous
          </button>

          {form?.status !== 'approved' && form?.status !== 'pending' ? (
            <button type="submit" className="submit-btn">
              Next
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                dispatch(changeStep(currentStep + 1));
              }}
              className="submit-btn"
            >
              Next
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

TransportDetails.propTypes = {
  currentStep: PropTypes.number,
};

export default TransportDetails;
