import {
  ADD_USER,
  ADD_USER_ERROR,
  ADD_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  FETCH_USER,
  FETCH_USERS,
  FETCH_USERS_FAIL,
  FETCH_USERS_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_SUCCESS,
  UNSET_USER,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
} from 'actions/userActions';
import { toast } from 'react-toastify';
import { failMessages, successMessages } from 'utils/constants/messages';

const initialState = {
  users: [],
  user: null,
  loading: false,
  error: null,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_USERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_USERS_SUCCESS: {
      const { results, ...rest } = payload;
      return {
        ...state,
        users: results,
        ...rest,
        loading: false,
      };
    }
    case FETCH_USERS_FAIL: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        user: payload,
        loading: false,
      };
    }
    case FETCH_USER_FAIL: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    case UNSET_USER: {
      return {
        ...state,
        user: null,
      };
    }
    case ADD_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_USER_SUCCESS: {
      toast.success(successMessages.addUser);
      setTimeout(() => {
        window.location.href = '/usermanagement';
      }, 1000);
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_USER_ERROR: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_USER_SUCCESS: {
      toast.success(successMessages.updateUser);
      setTimeout(() => {
        window.location.href = '/usermanagement';
      }, 1000);
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_USER_ERROR: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_USER_SUCCESS: {
      toast.success(successMessages.deleteUser);
      return {
        ...state,
        users: state.users.filter((user) => user.id !== payload),
        loading: false,
      };
    }
    case DELETE_USER_ERROR: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default userReducer;
