import React from 'react';
import PropTypes from 'prop-types';

function Avatar({ name, role }) {
  let nameArr = name.split(' ');
  let initials = '';
  if (nameArr?.[0]?.[0]) {
    initials += nameArr?.[0]?.[0];
  }
  if (nameArr?.[1]?.[0]) {
    initials += nameArr?.[1]?.[0];
  }

  let className = 'avatar';
  if (role === 'engineer') {
    className += ' blue';
  } else if (role === 'admin') {
    className += ' red';
  }

  return <>{initials !== '' && <div className={className}>{initials}</div>}</>;
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default Avatar;
