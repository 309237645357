import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { SIGN_IN_SUCCESS, SIGN_IN_ERROR, SIGN_IN_REQUEST } from 'actions/authActions';

function* handleSignIn(action) {
  try {
    const response = yield call(API.login, action.payload);
    yield put({ type: SIGN_IN_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: SIGN_IN_ERROR, payload: { error: e } });
  }
}

function* auth() {
  yield takeLatest(SIGN_IN_REQUEST, handleSignIn);
}
export default auth;
