import { deleteRequest, get, patch, post } from 'utils/api';

export const addUser = async (data) => {
  return post('/users', data);
};

export const fetchUsers = async (data) => {
  return get('/users', data);
};

export const fetchUser = async (userId) => {
  return get('/users/' + userId);
};

export const updateUser = async (data) => {
  return patch('/users/' + data.userId, data.values);
};

export const deleteUser = async (userId) => {
  return deleteRequest('/users/' + userId);
};

export const userApi = {
  addUser,
  fetchUsers,
  fetchUser,
  updateUser,
  deleteUser,
};
