import React from 'react';
import PropTypes from 'prop-types';

const InformationBlock = (props) => {
  const { infoText, infoNumber } = props;
  return (
    <div className="information_block">
      <div className="card">
        <div className="card-body text-end">
          <div className="h1 mb-1">{infoNumber}</div>
          <div className="subheader">{infoText}</div>
        </div>
      </div>
    </div>
  );
};

InformationBlock.propTypes = {
  infoNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  infoText: PropTypes.string,
};

export default InformationBlock;
