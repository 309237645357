import { authApi } from './authApi';
import { userApi } from './userApi';
import { formApi } from './formApi';
import { dashboardApi } from './dashboardApi';

export const API = {
  ...authApi,
  ...userApi,
  ...formApi,
  ...dashboardApi,
};
