import React, { useEffect } from 'react';
import Table from 'components/assignment/Table';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_CHARGERS } from 'actions/formActions';
import { FETCH_USERS } from 'actions/userActions';
import { Spinner } from 'react-bootstrap';
const Assignment = () => {
  const { chargers, loading } = useSelector((state) => state.forms);
  const { users } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const nonAdminUsers = users.filter((user) => user.role !== 'admin');

  useEffect(() => {
    dispatch({ type: FETCH_CHARGERS });
    dispatch({
      type: FETCH_USERS,
      payload: { limit: 999 },
    });
  }, []);

  return (
    <>
      <div className="page-body">
        <div className="container-xl  new_application">
          <div className="row gap-2  align-items-center">
            <div className="col">
              <h2 className="page-title">Assignment Module</h2>
            </div>
            <div className="col-12 mt-2 mb-2">
              {loading ? (
                <div className="loader-container">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <Table data={chargers} users={nonAdminUsers} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Assignment;
