import React, { useState } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import TextInput from 'components/inputs/Inputs';

const ForgotPassword = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <>
      <div className="d-flex flex-column login-page">
        <div className="page page-center">
          <div className="container container-tight py-4">
            <div className="card card-sm">
              <div className="text-center">
                <Link to="/" className="navbar-brand navbar-brand-autodark">
                  <h2 className="forgot-password">Forgot Password</h2>
                </Link>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={{ email: '' }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = 'Required email address';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                      errors.email = 'Invalid email address';
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      //alert(JSON.stringify(values, null, 2));
                      setSubmitting(false);
                    }, 400);
                    setSubmitted(true);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <>
                      {!submitted ? (
                        <form onSubmit={handleSubmit}>
                          <div>
                            <TextInput
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              size="md"
                              autoComplete="off"
                              placeholder="Enter Email Address"
                            />
                            <div className="error-message">{errors.email && touched.email && errors.email}</div>
                          </div>

                          <div className="form-footer">
                            <button type="submit" className="btn btn-primary w-100 send-password" disabled={isSubmitting}>
                              Send Password Reset Link
                            </button>
                          </div>
                        </form>
                      ) : (
                        <div className="text-center password-link">Password link sent to your email</div>
                      )}
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
