import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import FormikTextInput from 'components/inputs/FormikInputs';
import { changeStep, saveForm, updateForm } from 'actions/formActions';
import { useDispatch, useSelector } from 'react-redux';
import FormikDropDown from 'components/inputs/FormikDropDown';

const defaultValues = {
  stationName: '',
  projectType: '',
  documentNo: '',
  projectName: '',
  chargerSerialNo: '',
  chargerPowerRating: '',
  chargerId: '',
  locationAddress: '',
  dateOfCommission: '',
  chargerFirmwareVersion: '',
};
const Details = ({ currentStep }) => {
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state.forms);
  const [initialValues, setInitialValues] = useState(defaultValues);

  useEffect(() => {
    if (form !== null) {
      const documentNumber = form?.documentNo?.replace('NET/I&C/', '');
      setInitialValues({
        stationName: form.stationName,
        projectType: form.projectType,
        documentNo: documentNumber,
        projectName: form.projectName,
        chargerSerialNo: form.chargerSerialNo,
        chargerPowerRating: form.chargerPowerRating,
        chargerId: form.chargerId,
        locationAddress: form.locationAddress,
        chargerFirmwareVersion: form.chargerFirmwareVersion,
        dateOfCommission: form.dateOfCommission || new Date().toISOString().slice(0, 10),
      });
    } else {
      setInitialValues({
        ...defaultValues,
        dateOfCommission: new Date().toISOString().slice(0, 10),
      });
    }
  }, [JSON.stringify(form)]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const documentNumber = values.documentNo.startsWith('NET/I&C/') ? values.documentNo : `NET/I&C/${values.documentNo}`;
      values.documentNo = documentNumber;
      values.step = (currentStep + 1).toString();
      if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
        dispatch(updateForm(form?.id, values));
      } else {
        dispatch(saveForm(values));
      }
    },
  });
  const options = [
    { label: 'B2B', value: 'B2B' },
    { label: 'B2C COCO', value: 'B2C-COCO' },
    { label: 'B2C DOCO', value: 'B2C-DOCO' },
  ];

  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-3 ">
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Station Name (As per CMS/App)"
                isRequired
                name="stationName"
                formik={formik}
                placeholder="Enter Station Name"
                readOnly={form?.chargerAssignment}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikDropDown
                formik={formik}
                label="Project Type"
                name="projectType"
                options={options}
                isRequired
                className="text-input"
                placeholder="Select Project Type"
              />
            </div>
          </div>
        </div>
        <div className="row my-3 ">
          <div className="col-6">
            <div className="mb-3">
              <label htmlFor="documentNo" className="form-label">
                Document Number*
              </label>
              <div className="input-group net_ic">
                <span className="text-input">NET / I&C /</span>
                <FormikTextInput
                  type="text"
                  className="text-input"
                  isRequired
                  name="documentNo"
                  formik={formik}
                  id="documentNo"
                  placeholder="OCPPID"
                  value={formik.values?.documentNo?.replace('NET / I&C /', '')}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Project / Client Name"
                placeholder="Enter Project / Client Name "
                isRequired
                name="projectName"
                formik={formik}
              />
            </div>
          </div>
        </div>
        <div className="row my-3 ">
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Charger Serial Number"
                placeholder="Enter Charger Serial Number"
                isRequired
                name="chargerSerialNo"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Charger OEM/Power Rating/No of Connectors"
                isRequired
                name="chargerPowerRating"
                formik={formik}
                placeholder="Enter Charger OEM/Power Rating/No of Connectors"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Charger / OCPP ID"
                placeholder="Enter Charger / OCPP ID"
                isRequired
                name="chargerId"
                readOnly={form?.chargerAssignment}
                formik={formik}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Location / Address"
                placeholder="Enter Location / Address"
                isRequired
                name="locationAddress"
                formik={formik}
              />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                type="text"
                className="text-input"
                label="Firmware Version"
                placeholder="Enter Firmware Version"
                isRequired
                name="chargerFirmwareVersion"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput type="date" className="text-input" label="Date of Commissioning" isRequired name="dateOfCommission" formik={formik} />
            </div>
          </div>
        </div>
        <div className="buttons">
          {form?.status !== 'approved' && form?.status !== 'pending' ? (
            <button type="submit" className="submit-btn">
              Next
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                dispatch(changeStep(currentStep + 1));
              }}
              className="submit-btn"
            >
              Next
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

Details.propTypes = {
  currentStep: PropTypes.number,
};

export default Details;
