import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import TextInput from 'components/inputs/Inputs';
import RadioButton from 'components/inputs/RadioButton';
import PropTypes from 'prop-types';
import { propTypes } from 'react-bootstrap/esm/Image';
import { useDispatch, useSelector } from 'react-redux';
import { changeStep, updateForm } from 'actions/formActions';
import { handleImage } from 'helper/helper';
const ChargerInstallation = ({ currentStep }) => {
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state.forms);

  const [foundationChecked, setFoundationChecked] = useState('no');
  const [panelLevelChecked, setPanelLevelChecked] = useState('no');
  const [fastenerChecked, setFastenerChecked] = useState('no');
  const [fireExtinguisherChecked, setFireExtinguisherChecked] = useState('no');
  const [instructionBoardsChecked, setInstructionBoardsChecked] = useState('no');
  const [timerChecked, setTimerChecked] = useState('no');
  const [bollardsChecked, setBollardsChecked] = useState('no');
  const [checked, setChecked] = useState('completed');
  const [noReason, setNoReason] = useState('');
  const [drawingNumber, setDrawingNumber] = useState('');
  const [observations, setObservations] = useState('');
  const [chargerserialno, setChargerSerialNo] = useState('');
  const [panelno, setPanelNo] = useState('');

  // const [chargerSerialNoImage, setChargerSerialNoImage] = useState(null);
  // const [panelNoImage, setPanelNoImage] = useState(null);
  const [chargerSerialNoPreview, setChargerSerialNoPreview] = useState(null);
  const [panelNoPreview, setPanelNoPreview] = useState(null);

  useEffect(() => {
    if (form !== null) {
      let { chargerInstallationDetails = {} } = form;
      if (chargerInstallationDetails?.chargerCheck) {
        setFoundationChecked(chargerInstallationDetails?.chargerCheck);
      }
      if (chargerInstallationDetails?.drawingNumber) {
        setDrawingNumber(chargerInstallationDetails?.drawingNumber);
      }
      if (chargerInstallationDetails?.chargerPanelLevel) {
        setPanelLevelChecked(chargerInstallationDetails?.chargerPanelLevel);
      }
      if (chargerInstallationDetails?.chargerAnchor) {
        setFastenerChecked(chargerInstallationDetails?.chargerAnchor);
      }
      if (chargerInstallationDetails?.availability) {
        setFireExtinguisherChecked(chargerInstallationDetails?.availability);
      }
      if (chargerInstallationDetails?.installation) {
        setInstructionBoardsChecked(chargerInstallationDetails?.installation);
      }
      if (chargerInstallationDetails?.timer) {
        setTimerChecked(chargerInstallationDetails?.timer);
      }
      if (chargerInstallationDetails?.bollards) {
        setBollardsChecked(chargerInstallationDetails?.bollards);
      }
      if (chargerInstallationDetails?.noReason) {
        setNoReason(chargerInstallationDetails?.noReason);
      }
      if (chargerInstallationDetails?.observations) {
        setObservations(chargerInstallationDetails?.observations);
      }
      if (chargerInstallationDetails?.station_status) {
        setChecked(chargerInstallationDetails?.station_status);
      }
      if (chargerInstallationDetails?.chargerserialno) {
        //setChargerSerialNoImage(chargerInstallationDetails?.chargerserialno);
        setChargerSerialNoPreview(chargerInstallationDetails?.chargerserialno);
      }
      if (chargerInstallationDetails?.panelno) {
        //setPanelNoImage(chargerInstallationDetails?.panelno);
        setPanelNoPreview(chargerInstallationDetails?.panelno);
      }
    }
  }, [JSON.stringify(form)]);

  const handleChargerSerialNoChange = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      setChargerSerialNo(file);
      const reader = new FileReader();
      reader.onload = () => {
        setChargerSerialNoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setChargerSerialNo(null);
      setChargerSerialNoPreview(null);
    }
  };
  const handlePanelNoChange = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      setPanelNo(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPanelNoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPanelNo(null);
      setPanelNoPreview(null);
    }
  };

  const handleChangeNoReason = (value) => {
    setNoReason(value);
  };

  const handleChangeDrawingNumber = (value) => {
    setDrawingNumber(value);
  };

  const handleChange = (e) => {
    setChecked(e.target.value);
  };

  const submitForm = async () => {
    if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
      let chargerInstallationDetails = {
        chargerCheck: foundationChecked,
        ...(foundationChecked === 'yes' && { drawingNumber }),
        chargerPanelLevel: panelLevelChecked,
        chargerAnchor: fastenerChecked,
        station_status: checked,
        availability: fireExtinguisherChecked,
        installation: instructionBoardsChecked,
        timer: timerChecked,
        bollards: bollardsChecked,
        ...(bollardsChecked === 'no' && { noReason }),
        observations,
        chargerserialno: chargerserialno ? await handleImage(chargerserialno, `${form.id}_charger_serial_no`) : chargerSerialNoPreview,
        panelno: panelno ? await handleImage(panelno, `${form.id}_panel_no`) : panelNoPreview,
      };
      let step = (currentStep + 1).toString();
      let values = { chargerInstallationDetails, step };
      dispatch(updateForm(form?.id, values));
    } else {
      dispatch(changeStep(0));
    }
  };

  return (
    <div className="card-body">
      <div className="row my-3 ">
        <div className="col-12">
          <div className="card">
            <div className="table-responsive">
              <table className="table card-table table-vcenter text-nowrap datatable">
                <YesNoComponent
                  question="Charger Foundation is check as per the GA drawing of OEM"
                  value={foundationChecked}
                  onChange={setFoundationChecked}
                />
                {foundationChecked === 'yes' && <DrawingNumberInput value={drawingNumber} onChange={handleChangeDrawingNumber} />}
                <YesNoComponent question="Charger Panel Level (zero - zero)" value={panelLevelChecked} onChange={setPanelLevelChecked} />
                <YesNoComponent
                  question="Charger Anchor fastener is mounted as per the instructions"
                  value={fastenerChecked}
                  onChange={setFastenerChecked}
                />
                <YesNoComponent
                  question="Availability/ Installation of Fire Extinguisher"
                  value={fireExtinguisherChecked}
                  onChange={setFireExtinguisherChecked}
                />
                <YesNoComponent
                  question="Installation of instruction Boards"
                  value={instructionBoardsChecked}
                  onChange={setInstructionBoardsChecked}
                />
                <YesNoComponent question="Timer Set for Auxiliary Lights/LEDs" value={timerChecked} onChange={setTimerChecked} />
                <YesNoComponent
                  question="Installation of bollards/vehicle stopper near to charger"
                  value={bollardsChecked}
                  onChange={setBollardsChecked}
                />

                {bollardsChecked === 'no' && <ReasonInput value={noReason} onChange={handleChangeNoReason} />}
                <tr className="drawing_input">
                  <td>
                    <FormLabel>Station work completion status</FormLabel>
                  </td>
                  <td>
                    <div className="d-flex gap-3">
                      <RadioButton
                        id="station_status_completed"
                        containerClassName="radio-field"
                        value="completed"
                        checked={checked === 'completed'}
                        onChange={handleChange}
                      >
                        Completed
                      </RadioButton>
                      <RadioButton
                        id="station_status_pendings"
                        containerClassName="radio-field"
                        value="pending_others"
                        checked={checked === 'pending_others'}
                        onChange={handleChange}
                      >
                        Pending/Others
                      </RadioButton>
                    </div>
                  </td>
                </tr>
                <tr className="drawing_input">
                  <td>
                    <FormLabel>Other Comments/Observations</FormLabel>
                    <TextInput
                      className="text-input"
                      placeholder="Enter Other Comments/Observations"
                      value={observations}
                      name="observations"
                      onChange={(e) => {
                        let val = e.target.value;
                        setObservations(val);
                      }}
                    />
                  </td>
                </tr>
                <tr className="drawing_input">
                  <td colSpan={2}>
                    <div className="row mb-3">
                      <div className="col-6">
                        <FormLabel className="mb-3">Charger Serial Number Image</FormLabel>
                        {chargerSerialNoPreview && (
                          <div>
                            <img src={chargerSerialNoPreview} alt="Charger Serial Number" height={80} />
                          </div>
                        )}
                        {form?.status !== 'approved' && form?.status !== 'pending' && (
                          <TextInput
                            type="file"
                            className="text-input"
                            accept="image/*"
                            name="chargerserialno"
                            onChange={handleChargerSerialNoChange}
                          />
                        )}
                      </div>
                      <div className="col-6">
                        <FormLabel className="mb-3">Panel Number Image</FormLabel>
                        {panelNoPreview && (
                          <div>
                            <img src={panelNoPreview} alt="Panel Number" height={80} />
                          </div>
                        )}
                        {form?.status !== 'approved' && form?.status !== 'pending' && (
                          <TextInput type="file" accept="image/*" className="text-input" name="panelno" onChange={handlePanelNoChange} />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="buttons">
        <button
          type="button"
          className="prev-btn"
          onClick={() => {
            dispatch(changeStep(currentStep - 1));
          }}
        >
          Previous
        </button>

        {form?.status !== 'approved' && form?.status !== 'pending' ? (
          <button type="button" onClick={submitForm} className="submit-btn">
            Next
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              dispatch(changeStep(currentStep + 1));
            }}
            className="submit-btn"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

const YesNoComponent = ({ question, value, onChange }) => {
  return (
    <tr>
      <td>
        <FormLabel>{question}</FormLabel>
      </td>
      <td>
        <div className="storage-item">
          <div className="d-flex gap-3">
            <RadioButton
              id={question.split(' ').join('').toLowerCase() + 'yes'}
              value={'yes'}
              checked={value === 'yes'}
              onChange={() => onChange('yes')}
            >
              Yes
            </RadioButton>

            <RadioButton id={question.split(' ').join('').toLowerCase() + 'no'} value={'no'} checked={value === 'no'} onChange={() => onChange('no')}>
              No
            </RadioButton>
            <RadioButton id={question.split(' ').join('').toLowerCase() + 'na'} value={'na'} checked={value === 'na'} onChange={() => onChange('na')}>
              NA
            </RadioButton>
          </div>
        </div>
      </td>
    </tr>
  );
};

const DrawingNumberInput = ({ value, onChange }) => {
  return (
    <tr className="drawing_input">
      <td>
        <FormLabel>Drawing Number</FormLabel>
        <TextInput
          className=" text-input"
          placeholder="Enter Drawing Number"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </td>
    </tr>
  );
};

const ReasonInput = ({ value, onChange }) => {
  return (
    <tr className="drawing_input">
      <td>
        <FormLabel>If no, mention reason</FormLabel>
        <TextInput
          className="text-input"
          placeholder="Enter Reason"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </td>
    </tr>
  );
};

YesNoComponent.propTypes = {
  question: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
DrawingNumberInput.propTypes = {
  value: propTypes.string,
  onChange: PropTypes.func,
};
ReasonInput.propTypes = {
  value: propTypes.string,
  onChange: PropTypes.func,
};
ChargerInstallation.propTypes = {
  currentStep: PropTypes.number,
};
export default ChargerInstallation;
