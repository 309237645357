import { SET_USER, SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_ERROR } from 'actions/authActions';
import { cookie } from 'utils/cookies/index';
import { toast } from 'react-toastify';
import { REHYDRATE } from 'redux-persist/lib/constants';

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const roleAllowed = ['admin', 'engineer'];

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REHYDRATE: {
      return {
        ...state,
        ...payload?.auth,
      };
    }
    case SET_USER:
    case SIGN_IN_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case SIGN_IN_SUCCESS: {
      toast.success('Login successfully');
      cookie.set('token', payload.tokens.access.token);
      cookie.set('user-role', payload.user.role);
      if (roleAllowed.includes(payload.user.role)) {
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 1000);
      }
      return {
        ...state,
        user: payload,
        loading: false,
      };
    }
    case SIGN_IN_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default authReducer;
