import axios from 'axios';
import { startsWith } from 'lodash';
import { cookie } from '../cookies/index';
import { toast } from 'react-toastify';
const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const authHeader = () => {
  const token = cookie.get('token');
  return { Authorization: `Bearer ${token}` };
};

const url = (path, params) => {
  const sections = path.split(':');
  const sectionsWithParams = sections.map((section) => (startsWith(section, '/') ? section : params[section]));
  const pathWithParams = sectionsWithParams.join('');
  return process.env.REACT_APP_API_URL + pathWithParams;
};

const getHeaders = (auth) => {
  let headers = { ...defaultHeaders };

  if (auth) {
    headers = { ...headers, ...authHeader() };
  }
  return headers;
};

const apiService = axios.create({});

export const get = (path, params = {}, auth = true) => apiService.get(url(path, params), { params, headers: getHeaders(auth) });

export const post = (path, params = {}, auth = true) => apiService.post(url(path.replace(/\s+/g, ''), params), params, { headers: getHeaders(auth) });

export const put = (path, params = {}, auth = true) => apiService.put(url(path, params), params, { headers: getHeaders(auth) });

export const patch = (path, params = {}, auth = true) => apiService.patch(url(path, params), params, { headers: getHeaders(auth) });

export const deleteRequest = (path, params = {}, auth = true) => apiService.delete(url(path, params), { params, headers: getHeaders(auth) });

export const upload = (path, params = {}, auth = true) =>
  apiService.post(url(path, params), params, {
    headers: { ...getHeaders(auth), 'content-type': 'multipart/form-data' },
  });

export const download = (path, params = {}, auth = true) =>
  apiService.get(url(path, params), {
    responseType: 'blob',
    params,
    headers: getHeaders(auth),
  });

export const downloadFile = (path, params = {}, auth = true) =>
  apiService.post(url(path, params), params, {
    responseType: 'blob',
    headers: getHeaders(auth),
  });

// function cleanJSON(obj) {
//   //recursion to delete items from nested objects as well when its a blank or null value
//   Object.entries(obj).forEach(([key, val]) => (val && typeof val === 'object' && cleanJSON(val)) || (val === '' && delete obj[key]));
//   return obj;
// }

apiService.interceptors.request.use(
  function (config) {
    // if (['post', 'put', 'patch'].includes(config.method)) {
    //   config.data = cleanJSON(config.data);
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    }

    if (error && error.response && error.response.status === 401) {
      cookie.remove('token');
      window.location.href = '/login';
    }
    return Promise.reject(error.response);
  }
);
