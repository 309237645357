import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  ADD_USER,
  ADD_USER_ERROR,
  ADD_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  FETCH_USER,
  FETCH_USERS,
  FETCH_USERS_FAIL,
  FETCH_USERS_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
} from 'actions/userActions';

function* handleAddUser(action) {
  try {
    const response = yield call(API.addUser, action.payload);
    yield put({ type: ADD_USER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: ADD_USER_ERROR, payload: { error: e } });
  }
}

function* handleFetchUsers(action) {
  try {
    const response = yield call(API.fetchUsers, action.payload);
    yield put({ type: FETCH_USERS_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_USERS_FAIL, payload: { error: e } });
  }
}

function* handleFetchUser(action) {
  try {
    const response = yield call(API.fetchUser, action.payload);
    yield put({ type: FETCH_USER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_USER_FAIL, payload: { error: e } });
  }
}

function* handleUpdateUser(action) {
  try {
    const response = yield call(API.updateUser, action.payload);
    yield put({ type: UPDATE_USER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: UPDATE_USER_ERROR, payload: { error: e } });
  }
}

function* handleDeleteUser(action) {
  try {
    yield call(API.deleteUser, action.payload);
    yield put({ type: DELETE_USER_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_USER_ERROR, payload: { error: e } });
  }
}

function* users() {
  yield takeLatest(ADD_USER, handleAddUser);
  yield takeLatest(FETCH_USERS, handleFetchUsers);
  yield takeLatest(FETCH_USER, handleFetchUser);
  yield takeLatest(UPDATE_USER, handleUpdateUser);
  yield takeLatest(DELETE_USER, handleDeleteUser);
}
export default users;
