import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconChevronDown } from '@tabler/icons-react';
const UserDropdown = ({ users, onSelectUser, selectedUser }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleUserSelect = (user) => {
    onSelectUser(user.id, user);
    setIsOpen(false);
  };

  return (
    <div className="dropdown-user">
      <div className="dropdown-user-toggle" onClick={toggleDropdown}>
        {selectedUser ? `${selectedUser.name} ${selectedUser.lastName}` : 'Select Assignee'}
        <IconChevronDown className="caret" />
      </div>
      {isOpen && (
        <div className="dropdown-user-menu">
          {users.map((user) => (
            <div
              key={user.id}
              className={`dropdown-user-item ${selectedUser?.id === user.id ? 'dropdown-user-item-selected' : ''}`}
              onClick={() => handleUserSelect(user)}
            >
              {user.name} {user.lastName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

UserDropdown.propTypes = {
  users: PropTypes.array.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
};

export default UserDropdown;
