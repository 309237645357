import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/general/Avatar';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconDownload } from '@tabler/icons-react';
import downloadPDF from 'components/general/PreFormPDF';

const Table = ({ data, handleDeleteBtn = () => {} }) => {
  const navigate = useNavigate();
  const {
    user: { user },
  } = useSelector((state) => state.auth);

  const [filteredData, setFilteredData] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');
  const [usernameFilter, setUsernameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    let filtered = [...data];
    if (usernameFilter) {
      filtered = filtered.filter((row) =>
        `${row?.submittedBy?.name} ${row?.submittedBy?.lastName ?? ''}`
          .toLowerCase()
          .includes(usernameFilter.toLowerCase())
      );
    }
    if (statusFilter) {
      filtered = filtered.filter((row) => row.status.toLowerCase().includes(statusFilter.toLowerCase()));
    }
    if (searchTerm) {
      filtered = filtered.filter((row) =>
        `${row?.submittedBy?.name} ${row?.submittedBy?.lastName ?? ''}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.stationName ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (row.chargerId ?? '').toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (fromDate && toDate) {
      filtered = filtered.filter((row) => {
        const commissionDate = new Date(row.dateOfCommission);
        return commissionDate >= new Date(fromDate) && commissionDate <= new Date(toDate);
      });
    }
    setFilteredData(filtered);
  }, [data, usernameFilter, statusFilter, searchTerm, fromDate, toDate]);

  useEffect(() => {
    if (sortConfig.key !== null) {
      const sortedData = [...filteredData].sort((a, b) => {
        const factor = sortConfig.direction === 'ascending' ? 1 : -1;
        if (sortConfig.key === 'dateOfCommission') {
          return (new Date(a[sortConfig.key]) - new Date(b[sortConfig.key])) * factor;
        } else {
          return (a[sortConfig.key] ?? '').localeCompare(b[sortConfig.key] ?? '') * factor;
        }
      });
      setFilteredData(sortedData);
    }
  }, [sortConfig, filteredData]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleEditClick = (row) => {
    if (row.submittedBy?.id === user?.id) {
      navigate(`/allforms/form/edit/${row.id}`);
    } else if (row.reportingTo?.id === user?.id && row.status === 'pending') {
      navigate(`/allforms/form/review/${row.id}`);
    } else {
      navigate(`/allforms/form/view/${row.id}`);
    }
  };

  const clearAllFilters = () => {
    setSearchTerm('');
    setUsernameFilter('');
    setStatusFilter('');
    setFromDate('');
    setToDate('');
  };

  // Extracting unique usernames from the data
  const uniqueUsernames = [...new Set(data.map((row) => `${row?.submittedBy?.name} ${row?.submittedBy?.lastName ?? ''}`))];

  return (
    <>
      <div className="card">
        <div className="table-responsive">
          <div className="filters">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="filter-select">
              <select
                value={usernameFilter}
                onChange={(e) => setUsernameFilter(e.target.value)}
                className="form-control"
              >
                <option value="">Filter by Username</option>
                {uniqueUsernames.map((username, index) => (
                  <option key={index} value={username}>
                    {username}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-select">
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="form-control"
              >
                <option value="">Filter by Status</option>
                {['Pending', 'Approved', 'Re-Work', 'Ready to Commission', 'Draft'].map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
            <div className="date-filters">
              <input
                type="date"
                placeholder="From Date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="form-control"
              />
              <input
                type="date"
                placeholder="To Date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="form-control"
              />
            </div>
            <button className="clear-btn" onClick={clearAllFilters}>
              Clear All
            </button>
          </div>
          <table className="table card-table table-vcenter text-nowrap datatable pre-table">
            <tbody>
              <tr>
                <th onClick={() => handleSort('submittedBy.name')}>
                  Username{' '}
                  {sortConfig.key === 'submittedBy.name' && (
                    <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('dateOfCommission')}>
                  Commissioning Date{' '}
                  {sortConfig.key === 'dateOfCommission' && (
                    <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('stationName')}>
                  Station Name{' '}
                  {sortConfig.key === 'stationName' && (
                    <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('documentNo')}>
                  Document ID{' '}
                  {sortConfig.key === 'documentNo' && (
                    <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('chargerId')}>
                  OCPP ID{' '}
                  {sortConfig.key === 'chargerId' && (
                    <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                  )}
                </th>
                <th className="text-center">Actions</th>
              </tr>
              {filteredData.length > 0 ? (
                filteredData.map((row) => (
                  <tr key={row.id}>
                    <td className="user_name">
                      <div className="d-flex align-items-center gap-2">
                        <Avatar
                          role={row?.submittedBy?.role}
                          name={`${row?.submittedBy?.name} ${
                            row?.submittedBy?.lastName ? row?.submittedBy?.lastName : ''
                          }`}
                        />
                        <span>{`${row?.submittedBy?.name} ${
                          row?.submittedBy?.lastName ? row?.submittedBy?.lastName : ''
                        }`}</span>
                      </div>
                    </td>
                    <td>{row.dateOfCommission}</td>
                    <td>{row.stationName ?? '-'}</td>
                    <td>{row.documentNo ?? '-'}</td>
                    <td>{row.chargerId ?? '-'}</td>
                    <td>
                      <div className="d-flex gap-2 all_btn justify-content-end align-items-center">
                        <div className={`pending_btn ${row.status.toLowerCase()}_status`}>
                          {row.status === 'pending'
                            ? 'Pending'
                            : row.status === 'approved'
                            ? 'Approved'
                            : row.status === 'rework'
                            ? 'Re-Work'
                            : row.status === 'commission'
                            ? 'Ready to Commission'
                            : 'Draft'}
                        </div>
                        <div
                          className="edit_btn"
                          onClick={() => {
                            handleEditClick(row);
                          }}
                        >
                          {row.submittedBy?.id === user?.id
                            ? row.status === 'pending' || row.status === 'approved'
                              ? 'View'
                              : 'Edit'
                            : row.reportingTo?.id === user?.id && row.status === 'pending'
                            ? 'Review'
                            : 'View'}
                        </div>
                        <div
                          className="delete_btn"
                          onClick={() => {
                            handleDeleteBtn(row.id);
                          }}
                        >
                          Delete
                        </div>
                        <div className="download_btn" onClick={() => downloadPDF(row)}>
                          <IconDownload />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="user_name" colSpan={6}>
                    {' '}
                    No records found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  handleDeleteBtn: PropTypes.func,
};

export default Table;
