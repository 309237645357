import React from 'react';
import { Navigate } from 'react-router-dom';
import { cookie } from 'utils/cookies';

const withAuth = (BaseComponent) => {
  return function EnhancedComponent(props) {
    const isAuthenticated = cookie.get('token');

    if (isAuthenticated) {
      return <Navigate to="/dashboard" />;
    }
    // HOC-specific logic using hooks
    return <BaseComponent {...props} />;
  };
};

export default withAuth;
