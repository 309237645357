import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { cookie } from 'utils/cookies/index';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Avatar from 'components/general/Avatar';
const Header = (props) => {
  const { title = '' } = props;
  const onClickRemove = useCallback(() => {
    cookie.clean();
    window.location.href = '/login';
  }, []);
  const {
    user: { user },
  } = useSelector((state) => state.auth);
  return (
    <>
      <header className="navbar navbar-expand-md sticky-top d-none d-lg-flex d-print-none">
        <div className="container-xl ">
          <div className="row">
            <div className="col">
              <h2 className="page-title">{title}</h2>
            </div>
          </div>
          <div className="navbar-nav flex-row order-md-last">
            <div className="nav-item dropdown ">
              <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                <Avatar className="avatar avatar-sm" role={user?.role} name={`${user?.name} ${user?.lastName ? user?.lastName : ''}`} />

                <div className="d-none d-xl-block ps-2">
                  <div className="admin_name">
                    {user?.name}
                    {user?.lastName && ' ' + user?.lastName}
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <Link to="/" className="dropdown-item">
                  Profile
                </Link>
                <Link onClick={onClickRemove} className="dropdown-item">
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
Header.propTypes = {
  title: PropTypes.string,
};
export default Header;
