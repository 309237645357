import { deleteRequest, get, patch, post } from 'utils/api';

export const addForm = async (data) => {
  return post('/form', data);
};

export const assignCharger = async (data) => {
  return post('/form/chargers', data);
};

export const fetchChargers = async () => {
  return get('/form/chargers');
};

export const fetchForms = async (data) => {
  return get('/form', data);
};

export const fetchForm = async (formId) => {
  return get('/form/' + formId);
};

export const updateForm = async (data) => {
  return patch('/form/' + data.formId, data.values);
};

export const deleteForm = async (formId) => {
  return deleteRequest('/form/' + formId);
};

export const formApi = {
  addForm,
  assignCharger,
  fetchChargers,
  fetchForms,
  fetchForm,
  updateForm,
  deleteForm,
};
