import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { IconCaretDownFilled } from '@tabler/icons-react';

const Dropdown = ({ label, options, onChange = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const placeholder = label;
  const displayOption = (selectedOption?.label ? selectedOption?.label : selectedOption) || placeholder;

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setOpen(false);
    onChange(option);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className={`label ${selectedOption ? '' : 'place-holder'}`} onClick={toggleOpen}>
        {displayOption}
        <IconCaretDownFilled className="caret" />
      </div>
      {open && (
        <div className="options">
          {options.map((option) => (
            <div
              key={option?.value ? option?.value : option}
              className={JSON.stringify(option) === JSON.stringify(selectedOption) ? 'selected' : ''}
              onClick={() => handleOptionClick(option)}
            >
              {option?.label ? option.label : option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

export default Dropdown;
