import React, { useEffect, useState } from 'react';
import DeleteModal from 'components/general/DeleteModal';
import InformationBlock from 'components/general/InformationBlock';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_DASHBOARD } from 'actions/dashboardActions';
import { DELETE_FORM, FETCH_FORMS } from 'actions/formActions';
import Table from 'components/preform/Table';
import Pagination from 'components/general/Pagination';
import { Spinner } from 'react-bootstrap';
const Dashboard = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.dashboard);

  const { forms, loading: loadingForm, totalPages = '', totalResults = '' } = useSelector((state) => state.forms);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteFormId, setDeleteFormId] = useState(null);
  const [filterSettings, setFilterSettings] = useState({
    page: 1,
    limit: 5,
    sortBy: '-createdAt',
    status: 'pending',
  });

  useEffect(() => {
    dispatch({ type: FETCH_DASHBOARD });
    fetchForms();
  }, [JSON.stringify(filterSettings)]);

  const fetchForms = () => {
    dispatch({
      type: FETCH_FORMS,
      payload: filterSettings,
    });
  };

  const handleDeleteBtn = (formId) => {
    setDeleteFormId(formId);
    setShowDeleteModal(true);
  };

  const onRemove = () => {
    dispatch({ type: DELETE_FORM, payload: deleteFormId });
    setShowDeleteModal(false);
    setDeleteFormId(null);
    dispatch({ type: FETCH_DASHBOARD });
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setDeleteFormId(null);
  };

  const changePage = ({ selected: pageIndex }) => {
    setFilterSettings((state) => ({ ...state, page: pageIndex + 1 }));
  };

  return (
    <>
      <div className="page-body">
        {loading || loadingForm ? (
          <div className="loader-container">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="container-xl">
              <div className="row row-deck row-cards">
                <div className="col-sm-6 col-lg-3">
                  <InformationBlock infoText="Total Submissions" infoNumber={data?.totalCount} />
                </div>
                <div className="col-sm-6 col-lg-3">
                  <InformationBlock infoText="Total Approved" infoNumber={data?.totalCountApproved} />
                </div>
                <div className="col-sm-6 col-lg-3">
                  <InformationBlock infoText="Total Rejected" infoNumber={data?.totalCountReject} />
                </div>
                <div className="col-sm-6 col-lg-3">
                  <InformationBlock infoText="In Progress" infoNumber={data?.totalCountInProgress} />
                </div>
              </div>
            </div>
            <div className="container-xl pt-4 pb-4 new_application">
              <div className="row gap-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">All Forms</h2>
                </div>
                <div className="col-auto ms-auto d-print-none">
                  <div className="btn-list">
                    <Link to="/allforms/form" className="btn btn-primary d-sm-inline-block">
                      New Application
                    </Link>
                  </div>
                </div>
                <div className="col-12 mt-4 mb-4">
                  <Table data={forms} handleDeleteBtn={handleDeleteBtn} />
                  <Pagination
                    total={totalResults}
                    handlePageClick={changePage}
                    currentPage={filterSettings.page}
                    limit={filterSettings.limit}
                    totalPages={totalPages}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {showDeleteModal && <DeleteModal show={showDeleteModal} title="Form" onClose={handleCloseDeleteModel} onRemove={onRemove} />}
    </>
  );
};
export default Dashboard;
