export const formValidation = {
  required: 'Required',
  invalidEmail: 'Invalid email',
  invalidPhone: 'Invalid phone',
  invalidPassword: 'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number',
  invalidConfirmPassword: 'Passwords must match',
};

export const successMessages = {
  addUser: 'User added successfully.',
  updateUser: 'User updated successfully.',
  deleteUser: 'User deleted successfully.',
  fetchUsers: 'Users fetched successfully.',
  deleteForm: 'Form deleted successfully.',
};

export const failMessages = {
  error: 'Error Occured',
};
