import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import FormikDropdown from 'components/inputs/FormikDropDown';
import PropTypes from 'prop-types';
import RadioButton from 'components/inputs/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { changeStep, updateForm } from 'actions/formActions';
import FormikTextInput from 'components/inputs/FormikInputs';
import { useFormik } from 'formik';
import TagsInput from 'components/inputs/TagsInput';
const defaultValues = {
  networkConnectivityMode: '',
  simCardDetails: '',
  simNetworkProvider: '',
  simCardNumber: '',
  mobileNumber: '',
  ssid: '',
  password: '',
  ethernetNetworkProvider: '',
  staticIps: '',
};
const InternetDetails = ({ currentStep }) => {
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state.forms);
  const [checked, setChecked] = useState('dynamic');
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [staticIps, setStaticIps] = useState([]);

  useEffect(() => {
    if (form !== null) {
      const { internetDetails = {} } = form;
      setInitialValues({
        networkConnectivityMode: internetDetails?.networkConnectivityMode || '',
        simCardDetails: internetDetails?.simCardDetails || '',
        simNetworkProvider: internetDetails?.simNetworkProvider || '',
        simCardNumber: internetDetails?.simCardNumber || '',
        mobileNumber: internetDetails?.mobileNumber || '',
        ssid: internetDetails?.ssid || '',
        password: internetDetails?.password || '',
        ethernetNetworkProvider: internetDetails?.ethernetNetworkProvider || '',
      });
      if (internetDetails?.staticIps) {
        setStaticIps(internetDetails.staticIps);
      }
      if (internetDetails?.ips) {
        setChecked(internetDetails?.ips);
      }
    }
  }, [JSON.stringify(form)]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
        let body = {
          internetDetails: { ...values, ips: checked, staticIps },
        };
        body.step = (currentStep + 1).toString();
        dispatch(updateForm(form?.id, body));
      } else {
        dispatch(changeStep(0));
      }
    },
  });

  const onChangeStaticIps = (value) => {
    setStaticIps(value);
  };

  const options = [
    { label: 'SIM', value: 'SIM' },
    { label: 'WIFI', value: 'WIFI' },
    { label: 'ETHERNET / LEASED LINE', value: 'ETHERNET' },
    { label: 'CUSTOMER SCOPE', value: 'CUSTOMER SCOPE' },
  ];
  const options1 = [
    { label: 'VOICE / DATAs', value: 'VOICE' },
    { label: 'M2M', value: 'M2M' },
    { label: 'NA', value: 'NA' },
  ];
  const options2 = [
    { label: 'AIRTEL', value: 'AIRTEL' },
    { label: 'IDEA', value: 'IDEA' },
    { label: 'VI', value: 'VI' },
    { label: 'BSNL', value: 'BSNL' },
    { label: 'JIO', value: 'JIO' },
  ];
  const handleChange = (e) => {
    setChecked(e.target.value);
  };
  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="row my-3">
          <div className="col-12">
            <div className="mb-3">
              <FormikDropdown
                label="Network Connectivity Mode"
                placeholder="Select Network Connectivity Mode"
                options={options}
                isRequired
                name="networkConnectivityMode"
                formik={formik}
              />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12">
            <div className="mb-3">
              <FormikDropdown
                label="Sim Card Details"
                placeholder="Select Sim Card Details"
                options={options1}
                isRequired
                name="simCardDetails"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <FormikDropdown
                  label="Network Provider"
                  placeholder="Select Network Provider"
                  options={options2}
                  isRequired
                  name="simNetworkProvider"
                  formik={formik}
                />
              </div>
              <div className="col-4">
                <FormikTextInput
                  type="text"
                  label="SIM / ICCID Card Number"
                  placeholder="Enter SIM / ICCID Card Number"
                  className="text-input"
                  isRequired
                  name="simCardNumber"
                  formik={formik}
                />
              </div>
              <div className="col-4">
                <FormikTextInput
                  type="text"
                  label="Mobile Number"
                  placeholder="Enter Mobile Number"
                  className="text-input"
                  isRequired
                  name="mobileNumber"
                  formik={formik}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12">
            <div className="mb-3">
              <FormLabel className="form-label">Wi-fi Connection Details</FormLabel>
              <div className="divider"></div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <FormikTextInput type="text" label="SSID" placeholder="Enter SSID" className="text-input" isRequired name="ssid" formik={formik} />
              </div>
              <div className="col-6">
                <FormikTextInput
                  type="text"
                  label="Password"
                  placeholder="Enter Password"
                  className="text-input"
                  isRequired
                  name="password"
                  formik={formik}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12">
            <div className="mb-3">
              <FormLabel className="form-label">Ethernet / Leased Lined Details</FormLabel>
              <div className="divider"></div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <FormikTextInput
                  type="text"
                  label="Network Provider"
                  placeholder="Enter Network Provider"
                  className="text-input"
                  isRequired
                  name="ethernetNetworkProvider"
                  formik={formik}
                />
              </div>
              <div className="col-6">
                <FormLabel className="form-label">IPS</FormLabel>
                <div className="storage-item ">
                  <div className=" d-flex gap-3">
                    <RadioButton id="static" containerClassName="radio-field" value="static" checked={checked === 'static'} onChange={handleChange}>
                      Static
                    </RadioButton>
                    <RadioButton
                      id="dynamic"
                      containerClassName="radio-field"
                      value="dynamic"
                      checked={checked === 'dynamic'}
                      onChange={handleChange}
                    >
                      Dynamic
                    </RadioButton>
                  </div>
                </div>
              </div>
            </div>
            {checked === 'static' && (
              <div className="row mt-3">
                <div className="col-12">
                  <FormLabel>List of Static IPs & Configuration Details With Charger</FormLabel>
                  <TagsInput className="text-input" name="staticIps" value={staticIps} onChange={onChangeStaticIps} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="buttons">
          <button
            type="button"
            className="prev-btn"
            onClick={() => {
              dispatch(changeStep(currentStep - 1));
            }}
          >
            Previous
          </button>

          {form?.status !== 'approved' && form?.status !== 'pending' ? (
            <button type="submit" className="submit-btn">
              Next
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                dispatch(changeStep(currentStep + 1));
              }}
              className="submit-btn"
            >
              Next
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

InternetDetails.propTypes = {
  currentStep: PropTypes.number,
};

export default InternetDetails;
