//Add  Form
export const ADD_FORM = 'ADD_FORM';
export const ADD_FORM_SUCCESS = 'ADD_FORM_SUCCESS';
export const ADD_FORM_ERROR = 'ADD_FORM_ERROR';

//Assign CHARGER
export const ASSIGN_CHARGER = 'ASSIGN_CHARGER';
export const ASSIGN_CHARGER_SUCCESS = 'ASSIGN_CHARGER_SUCCESS';
export const ASSIGN_CHARGER_FAIL = 'ASSIGN_CHARGER_FAIL';

//Fetch  CHARGERS
export const FETCH_CHARGERS = 'FETCH_CHARGERS';
export const FETCH_CHARGERS_SUCCESS = 'FETCH_CHARGERS_SUCCESS';
export const FETCH_CHARGERS_FAIL = 'FETCH_CHARGERS_FAIL';

//Fetch  Forms
export const FETCH_FORMS = 'FETCH_FORMS';
export const FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS';
export const FETCH_FORMS_FAIL = 'FETCH_FORMS_FAIL';

//Fetch  Forms
export const FETCH_FORM = 'FETCH_FORM';
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS';
export const FETCH_FORM_FAIL = 'FETCH_FORM_FAIL';
export const UNSET_FORM = 'UNSET_FORM';

//Update  Form
export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS';
export const UPDATE_FORM_ERROR = 'UPDATE_FORM_ERROR';

//Delete  Form
export const DELETE_FORM = 'DELETE_FORM';
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS';
export const DELETE_FORM_ERROR = 'DELETE_FORM_ERROR';

export const CHANGE_STEP = 'CHANGE_STEP';

export const changeStep = (step) => {
  return { type: CHANGE_STEP, payload: step };
};

export const getForm = (formId) => {
  return { type: FETCH_FORM, payload: formId };
};

export const saveForm = (values) => {
  return { type: ADD_FORM, payload: values };
};

export const updateForm = (formId, values) => {
  return {
    type: UPDATE_FORM,
    payload: {
      formId,
      values,
    },
  };
};

export const unsetForm = () => {
  return { type: UNSET_FORM };
};
