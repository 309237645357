import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import { FETCH_DASHBOARD, FETCH_DASHBOARD_FAIL, FETCH_DASHBOARD_SUCCESS } from 'actions/dashboardActions';

function* handleFetchDashboard(action) {
  try {
    const response = yield call(API.fetchDashboardData, action.payload);
    yield put({ type: FETCH_DASHBOARD_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_DASHBOARD_FAIL, payload: { error: e } });
  }
}

function* preForm() {
  yield takeLatest(FETCH_DASHBOARD, handleFetchDashboard);
}
export default preForm;
