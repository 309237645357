import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import SitesyncLogo from 'assets/images/logo.png';
import { navs } from 'utils/constants/nav';
import { cookie } from 'utils/cookies';
const Sidebar = () => {
  const location = useLocation();
  const userRole = cookie.get('user-role');
  return (
    <>
      <aside className="navbar navbar-vertical navbar-expand-lg" data-bs-theme="dark">
        <div className="container-fluid">
          <Link to="/" className="d-flex justify-content-center gap-2 ">
            <img src={SitesyncLogo} height="50" alt="Sitesync Logo" />
            <h1 className="navbar-brand navbar-brand-autodark mt-1">SiteSync</h1>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebar-menu"
            aria-controls="sidebar-menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="sidebar-menu">
            <ul className="navbar-nav pt-lg-3">
              {navs.map(
                (nav) =>
                  nav.rolesAllowed.includes(userRole) && (
                    <li key={nav.name.toLowerCase()} className={location.pathname.includes(nav.path) ? 'nav-item active' : 'nav-item'}>
                      <Link className="nav-link" to={nav.path}>
                        <span className="nav-link-title">{nav.name}</span>
                      </Link>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
