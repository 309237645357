import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import FormikDropdown from 'components/inputs/FormikDropDown';
import FormikTextInput from 'components/inputs/FormikInputs';
import { addUserSchema, updateUserSchema } from 'utils/schema/userSchema';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_USER, FETCH_USER, UNSET_USER, UPDATE_USER } from 'actions/userActions';
import { useParams } from 'react-router-dom';

const defaultValues = {
  name: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  role: '',
};
const User = () => {
  const { userId = '' } = useParams();
  const [formInitialValues, setFormInitialValues] = useState(defaultValues);

  const { user: userData } = useSelector((state) => state.users);

  useEffect(() => {
    if (userId !== '') {
      fetchUser(userId);
    } else {
      setFormInitialValues(defaultValues);
    }

    return () => {
      dispatch({ type: UNSET_USER });
    };
  }, [userId]);

  useEffect(() => {
    if (userData !== null) {
      setFormInitialValues({
        name: userData.name,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        password: '',
        confirmPassword: '',
        role: userData.role,
      });
    }
  }, [JSON.stringify(userData)]);

  const dispatch = useDispatch();
  const options = [
    { label: 'Admin', value: 'admin' },
    { label: 'Engineer', value: 'engineer' },
  ];

  const submitForm = (values) => {
    try {
      delete values.confirmPassword;
      if (userId !== '') {
        delete values.role;
        if (values.password === '') {
          delete values.password;
        }
        dispatch({ type: UPDATE_USER, payload: { userId, values } });
      } else {
        dispatch({ type: ADD_USER, payload: values });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: userId !== '' ? updateUserSchema : addUserSchema,
    onSubmit: submitForm,
    enableReinitialize: true,
  });

  const fetchUser = (userId) => {
    dispatch({ type: FETCH_USER, payload: userId });
  };

  return (
    <div className="page-body">
      <div className="preform">
        <div className="container-xl p-3">
          <h4>{userId !== '' ? 'Edit' : 'Create'} a User</h4>
          <div className="divider"></div>
          <form onSubmit={formik.handleSubmit}>
            <div className="row my-3 ">
              <div className="col-6">
                <FormikTextInput type="text" className="text-input" isRequired label="First Name" name="name" formik={formik} />
              </div>
              <div className="col-6">
                <FormikTextInput label="Last Name" isRequired type="text" className="text-input" name="lastName" formik={formik} />
              </div>
            </div>
            <div className="row my-3 ">
              <div className="col-6">
                <FormikTextInput type="text" className="text-input" label="Phone" isRequired name="phone" formik={formik} />
              </div>
              <div className="col-6">
                <FormikTextInput type="email" className="text-input" label="Email" isRequired name="email" formik={formik} />
              </div>
            </div>
            <div className="row my-3 ">
              <div className="col-6">
                <FormikTextInput type="password" className="text-input" label="Password" isRequired name="password" formik={formik} />
              </div>
              <div className="col-6">
                <FormikTextInput type="password" className="text-input" label="Confirm Password" isRequired name="confirmPassword" formik={formik} />
              </div>
            </div>
            <div className="row my-3 ">
              <div className="col-6">
                <FormikDropdown
                  label="Role"
                  readOnly={userId !== ''}
                  placeholder="Select an Option"
                  options={options}
                  isRequired
                  name="role"
                  formik={formik}
                />
              </div>
              {/* <div className="col-6">
                <FormikDropdown label="Reporting Manger" placeholder="Select an Option" options={options} />
              </div> */}
            </div>
            <div className="row my-3">
              <div className="col-12">
                <div className="buttons">
                  <button type="submit" className="submit-btn" disabled={formik.isSubmitting}>
                    {userId !== '' ? 'Update' : 'Create New'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default User;
