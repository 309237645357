import React, { useEffect, useState } from 'react';
import RadioButton from 'components/inputs/RadioButton';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { changeStep, updateForm } from 'actions/formActions';
import { useFormik } from 'formik';
import FormikTextInput from 'components/inputs/FormikInputs';
import { FormLabel } from 'react-bootstrap';

const defaultValues = {
  name: '',
  contactNo: '',
  alternateContact: '',
  alternateContactNumber: '',
};

const Contact = ({ currentStep }) => {
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state.forms);
  const [checked, setChecked] = useState('no');
  const [initialValues, setInitialValues] = useState(defaultValues);
  useEffect(() => {
    if (form !== null) {
      const { personOfContact = {} } = form;
      setInitialValues({
        name: personOfContact?.name || '',
        contactNo: personOfContact?.contactNo || '',
        alternateContact: personOfContact?.alternateContact || '',
        alternateContactNumber: personOfContact?.alternateContactNumber || '',
      });
      if (personOfContact?.keyHandover) {
        setChecked(personOfContact?.keyHandover);
      }
    }
  }, [JSON.stringify(form)]);
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
        let body = {
          personOfContact: { ...values, keyHandover: checked },
        };
        body.step = (currentStep + 1).toString();
        dispatch(updateForm(form?.id, body));
      } else {
        dispatch(changeStep(0));
      }
    },
  });

  const handleChange = (e) => {
    setChecked(e.target.value);
  };
  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-3 ">
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput label="Name" placeholder="Enter Name" type="text" className="text-input" name="name" formik={formik} />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                label="Contact Number"
                placeholder="Enter Contact Number"
                type="text"
                className="text-input"
                name="contactNo"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                label="Alternate Contact Name"
                placeholder="Enter Alternate Contact Name"
                type="text"
                className="text-input"
                name="alternateContact"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <FormikTextInput
                label="Alternate Contact Number"
                placeholder="Enter Alternate Contact Number"
                type="text"
                className="text-input"
                name="alternateContactNumber"
                formik={formik}
              />
            </div>
          </div>
          <div className="col-12">
            <FormLabel htmlFor="storage" className="form-label">
              Charger Key handover or not?
            </FormLabel>
            <div className="storage-item d-flex gap-2 align-items-center">
              <div className=" d-flex gap-3">
                <RadioButton id="keyHandover_yes" containerClassName="radio-field" value="Yes" checked={checked === 'Yes'} onChange={handleChange}>
                  Yes
                </RadioButton>
                <RadioButton id="keyHandover_no" containerClassName="radio-field" value="No" checked={checked === 'No'} onChange={handleChange}>
                  No
                </RadioButton>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button
            type="button"
            className="prev-btn"
            onClick={() => {
              dispatch(changeStep(currentStep - 1));
            }}
          >
            Previous
          </button>

          {form?.status !== 'approved' && form?.status !== 'pending' ? (
            <button type="submit" className="submit-btn">
              Next
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                dispatch(changeStep(currentStep + 1));
              }}
              className="submit-btn"
            >
              Next
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

Contact.propTypes = {
  currentStep: PropTypes.number,
};

export default Contact;
