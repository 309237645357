import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { changeStep, updateForm } from 'actions/formActions';
import FormikTextInput from 'components/inputs/FormikInputs';
import { FormLabel } from 'react-bootstrap';
const defaultValues = {
  ry: {
    mainPanel: {
      noLoad: '',
      fullLoad: '',
    },
    ACDBPanel: {
      noLoad: '',
      fullLoad: '',
    },
    chargerIP: {
      noLoad: '',
      fullLoad: '',
    },
  },
  yb: {
    mainPanel: {
      noLoad: '',
      fullLoad: '',
    },
    ACDBPanel: {
      noLoad: '',
      fullLoad: '',
    },
    chargerIP: {
      noLoad: '',
      fullLoad: '',
    },
  },
  br: {
    mainPanel: {
      noLoad: '',
      fullLoad: '',
    },
    ACDBPanel: {
      noLoad: '',
      fullLoad: '',
    },
    chargerIP: {
      noLoad: '',
      fullLoad: '',
    },
  },
  rn: {
    mainPanel: {
      noLoad: '',
      fullLoad: '',
    },
    ACDBPanel: {
      noLoad: '',
      fullLoad: '',
    },
    chargerIP: {
      noLoad: '',
      fullLoad: '',
    },
  },
  yn: {
    mainPanel: {
      noLoad: '',
      fullLoad: '',
    },
    ACDBPanel: {
      noLoad: '',
      fullLoad: '',
    },
    chargerIP: {
      noLoad: '',
      fullLoad: '',
    },
  },
  bn: {
    mainPanel: {
      noLoad: '',
      fullLoad: '',
    },
    ACDBPanel: {
      noLoad: '',
      fullLoad: '',
    },
    chargerIP: {
      noLoad: '',
      fullLoad: '',
    },
  },
  ne: {
    mainPanel: {
      noLoad: '',
      fullLoad: '',
    },
    ACDBPanel: {
      noLoad: '',
      fullLoad: '',
    },
    chargerIP: {
      noLoad: '',
      fullLoad: '',
    },
  },
  earthPit1Location: '',
  earthPit2Location: '',
  earthPit1Resistance: '',
  earthPit2Resistance: '',
  phase: {
    r: '',
    y: '',
    b: '',
    rcmnl: '',
    rpmfl: '',
    rpmnl: '',
    ycmnl: '',
    ypmfl: '',
    ypmnl: '',
    bcmnl: '',
    bpmfl: '',
    bpmnl: '',
  },
};

const CircuitMeasurements = ({ currentStep }) => {
  const dispatch = useDispatch();
  const { form } = useSelector((state) => state.forms);
  const [initialValues, setInitialValues] = useState(defaultValues);

  useEffect(() => {
    if (form !== null) {
      const { powerMeasurement = {} } = form;
      setInitialValues({
        ry: {
          mainPanel: {
            noLoad: powerMeasurement?.ry?.mainPanel?.noLoad || '',
            fullLoad: powerMeasurement?.ry?.mainPanel?.fullLoad || '',
          },
          ACDBPanel: {
            noLoad: powerMeasurement?.ry?.ACDBPanel?.noLoad || '',
            fullLoad: powerMeasurement?.ry?.ACDBPanel?.fullLoad || '',
          },
          chargerIP: {
            noLoad: powerMeasurement?.ry?.chargerIP?.noLoad || '',
            fullLoad: powerMeasurement?.ry?.chargerIP?.fullLoad || '',
          },
        },
        yb: {
          mainPanel: {
            noLoad: powerMeasurement?.yb?.mainPanel?.noLoad || '',
            fullLoad: powerMeasurement?.yb?.mainPanel?.fullLoad || '',
          },
          ACDBPanel: {
            noLoad: powerMeasurement?.yb?.ACDBPanel?.noLoad || '',
            fullLoad: powerMeasurement?.yb?.ACDBPanel?.fullLoad || '',
          },
          chargerIP: {
            noLoad: powerMeasurement?.yb?.chargerIP?.noLoad || '',
            fullLoad: powerMeasurement?.yb?.chargerIP?.fullLoad || '',
          },
        },
        br: {
          mainPanel: {
            noLoad: powerMeasurement?.br?.mainPanel?.noLoad || '',
            fullLoad: powerMeasurement?.br?.mainPanel?.fullLoad || '',
          },
          ACDBPanel: {
            noLoad: powerMeasurement?.br?.ACDBPanel?.noLoad || '',
            fullLoad: powerMeasurement?.br?.ACDBPanel?.fullLoad || '',
          },
          chargerIP: {
            noLoad: powerMeasurement?.br?.chargerIP?.noLoad || '',
            fullLoad: powerMeasurement?.br?.chargerIP?.fullLoad || '',
          },
        },
        rn: {
          mainPanel: {
            noLoad: powerMeasurement?.rn?.mainPanel?.noLoad || '',
            fullLoad: powerMeasurement?.rn?.mainPanel?.fullLoad || '',
          },
          ACDBPanel: {
            noLoad: powerMeasurement?.rn?.ACDBPanel?.noLoad || '',
            fullLoad: powerMeasurement?.rn?.ACDBPanel?.fullLoad || '',
          },
          chargerIP: {
            noLoad: powerMeasurement?.rn?.chargerIP?.noLoad || '',
            fullLoad: powerMeasurement?.rn?.chargerIP?.fullLoad || '',
          },
        },
        yn: {
          mainPanel: {
            noLoad: powerMeasurement?.yn?.mainPanel?.noLoad || '',
            fullLoad: powerMeasurement?.yn?.mainPanel?.fullLoad || '',
          },
          ACDBPanel: {
            noLoad: powerMeasurement?.yn?.ACDBPanel?.noLoad || '',
            fullLoad: powerMeasurement?.yn?.ACDBPanel?.fullLoad || '',
          },
          chargerIP: {
            noLoad: powerMeasurement?.yn?.chargerIP?.noLoad || '',
            fullLoad: powerMeasurement?.yn?.chargerIP?.fullLoad || '',
          },
        },
        bn: {
          mainPanel: {
            noLoad: powerMeasurement?.bn?.mainPanel?.noLoad || '',
            fullLoad: powerMeasurement?.bn?.mainPanel?.fullLoad || '',
          },
          ACDBPanel: {
            noLoad: powerMeasurement?.bn?.ACDBPanel?.noLoad || '',
            fullLoad: powerMeasurement?.bn?.ACDBPanel?.fullLoad || '',
          },
          chargerIP: {
            noLoad: powerMeasurement?.bn?.chargerIP?.noLoad || '',
            fullLoad: powerMeasurement?.bn?.chargerIP?.fullLoad || '',
          },
        },
        ne: {
          mainPanel: {
            noLoad: powerMeasurement?.ne?.mainPanel?.noLoad || '',
            fullLoad: powerMeasurement?.ne?.mainPanel?.fullLoad || '',
          },
          ACDBPanel: {
            noLoad: powerMeasurement?.ne?.ACDBPanel?.noLoad || '',
            fullLoad: powerMeasurement?.ne?.ACDBPanel?.fullLoad || '',
          },
          chargerIP: {
            noLoad: powerMeasurement?.ne?.chargerIP?.noLoad || '',
            fullLoad: powerMeasurement?.ne?.chargerIP?.fullLoad || '',
          },
        },
        earthPit1Location: powerMeasurement?.earthPit1Location || '',
        earthPit2Location: powerMeasurement?.earthPit2Location || '',
        earthPit1Resistance: powerMeasurement?.earthPit1Resistance || '',
        earthPit2Resistance: powerMeasurement?.earthPit2Resistance || '',
        phase: {
          r: powerMeasurement?.phase?.r || '',
          y: powerMeasurement?.phase?.y || '',
          b: powerMeasurement?.phase?.b || '',
          rcmnl: powerMeasurement?.phase?.rcmnl || '',
          rpmfl: powerMeasurement?.phase?.rpmfl || '',
          rpmnl: powerMeasurement?.phase?.rpmnl || '',
          ycmnl: powerMeasurement?.phase?.ycmnl || '',
          ypmfl: powerMeasurement?.phase?.ypmfl || '',
          ypmnl: powerMeasurement?.phase?.ypmnl || '',
          bcmnl: powerMeasurement?.phase?.bcmnl || '',
          bpmfl: powerMeasurement?.phase?.bpmfl || '',
          bpmnl: powerMeasurement?.phase?.bpmnl || '',
        },
      });
    }
  }, [JSON.stringify(form)]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (form !== null && form?.id !== undefined && form?.status !== 'approved' && form?.status !== 'pending') {
        let body = {
          powerMeasurement: values,
        };
        body.step = (currentStep + 1).toString();
        dispatch(updateForm(form?.id, body));
      } else {
        dispatch(changeStep(0));
      }
    },
  });

  return (
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
        <div className="card my-4  ">
          <div className="table-responsive">
            <table className="table card-table align-middle text-center text-nowrap datatable  circuit ">
              <thead>
                <tr>
                  <th colSpan="1" rowSpan="3" scope="colgroup" className="align-middle">
                    Phase/Line
                  </th>
                  <th colSpan="2" scope="colgroup">
                    Main Panel
                  </th>
                  <th colSpan="2" scope="colgroup">
                    Charger ACDB Panel
                  </th>
                  <th colSpan="2" scope="colgroup">
                    Charger I/P
                  </th>
                </tr>
                <tr>
                  <th colSpan="2">O/P Voltage (Volt)</th>
                  <th colSpan="2">O/P Voltage (Volt)</th>
                  <th colSpan="2">Voltage (Volt)</th>
                </tr>
                <tr>
                  <th colSpan="col">No Load</th>
                  <th colSpan="col">Full Load</th>
                  <th colSpan="col">No Load</th>
                  <th colSpan="col">Full Load</th>
                  <th colSpan="col">No Load</th>
                  <th colSpan="col">Full Load</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>R-Y</td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ry.mainPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ry.mainPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ry.ACDBPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ry.ACDBPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ry.chargerIP.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ry.chargerIP.fullLoad" formik={formik} />
                  </td>
                </tr>
                <tr>
                  <td>Y-B</td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yb.mainPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yb.mainPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yb.ACDBPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yb.ACDBPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yb.chargerIP.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yb.chargerIP.fullLoad" formik={formik} />
                  </td>
                </tr>
                <tr>
                  <td>B-R</td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="br.mainPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="br.mainPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="br.ACDBPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="br.ACDBPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="br.chargerIP.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="br.chargerIP.fullLoad" formik={formik} />
                  </td>
                </tr>
                <tr>
                  <td>R-N</td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="rn.mainPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="rn.mainPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="rn.ACDBPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="rn.ACDBPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="rn.chargerIP.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="rn.chargerIP.fullLoad" formik={formik} />
                  </td>
                </tr>
                <tr>
                  <td>Y-N</td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yn.mainPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yn.mainPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yn.ACDBPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yn.ACDBPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yn.chargerIP.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="yn.chargerIP.fullLoad" formik={formik} />
                  </td>
                </tr>
                <tr>
                  <td>B-N</td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="bn.mainPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="bn.mainPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="bn.ACDBPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="bn.ACDBPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="bn.chargerIP.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="bn.chargerIP.fullLoad" formik={formik} />
                  </td>
                </tr>
                <tr>
                  <td>N-E</td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ne.mainPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ne.mainPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ne.ACDBPanel.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ne.ACDBPanel.fullLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ne.chargerIP.noLoad" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="ne.chargerIP.fullLoad" formik={formik} />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="row m-2 earth_pit mb-4">
              <div className="col-12 mt-3">
                <div className="md-3">
                  <FormLabel>Earth Pit 1</FormLabel>
                  <div className="row">
                    <div className="col-6">
                      <FormikTextInput
                        label="Location"
                        placeholder="Enter Location"
                        type="text"
                        className="text-input"
                        isRequired
                        name="earthPit1Location"
                        formik={formik}
                      />
                    </div>
                    <div className="col-6">
                      <FormikTextInput
                        label="Earthing Resistance (Ohms)"
                        placeholder="Enter Earthing Resistance (Ohms)"
                        type="text"
                        className="text-input"
                        isRequired
                        name="earthPit1Resistance"
                        formik={formik}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="md-3">
                  <FormLabel>Earth Pit 2</FormLabel>
                  <div className="row">
                    <div className="col-6">
                      <FormikTextInput
                        label="Location"
                        type="text"
                        placeholder="Enter Location"
                        className="text-input"
                        isRequired
                        name="earthPit2Location"
                        formik={formik}
                      />
                    </div>
                    <div className="col-6">
                      <FormikTextInput
                        label="Earthing Resistance (Ohms)"
                        placeholder="Enter Earthing Resistance (Ohms)"
                        type="text"
                        className="text-input"
                        isRequired
                        name="earthPit2Resistance"
                        formik={formik}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table className="table card-table align-middle text-center text-nowrap datatable  circuit">
              <thead>
                <tr>
                  <th colSpan="1" rowSpan="2" className="align-middle">
                    Phase
                  </th>
                  <th colSpan="2">Current Measurement (Amp)</th>
                  <th colSpan="2">Power Measurement (W)</th>
                </tr>
                <tr>
                  <th colSpan="col">No Load</th>
                  <th colSpan="col">Full Load</th>
                  <th colSpan="col">No Load</th>
                  <th colSpan="col">Full Load</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>R</td>

                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="phase.rcmnl" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="phase.r" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput
                      type="text"
                      className="text-input"
                      isRequired
                      name="phase.rpmnl"
                      formik={formik}
                      value={formik.values.rn.chargerIP.noLoad * formik.values.phase.rcmnl}
                      disabled
                    />
                  </td>
                  <td>
                    <FormikTextInput
                      type="text"
                      className="text-input"
                      isRequired
                      name="phase.rpmfl"
                      formik={formik}
                      value={formik.values.rn.chargerIP.fullLoad * formik.values.phase.r}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td>Y</td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="phase.ycmnl" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="phase.y" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput
                      type="text"
                      className="text-input"
                      isRequired
                      name="phase.ypmnl"
                      formik={formik}
                      value={formik.values.yn.chargerIP.noLoad * formik.values.phase.ycmnl}
                      disabled
                    />
                  </td>
                  <td>
                    <FormikTextInput
                      type="text"
                      className="text-input"
                      isRequired
                      name="phase.ypmfl"
                      formik={formik}
                      value={formik.values.yn.chargerIP.fullLoad * formik.values.phase.y}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="phase.bcmnl" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput type="text" className="text-input" isRequired name="phase.b" formik={formik} />
                  </td>
                  <td>
                    <FormikTextInput
                      type="text"
                      className="text-input"
                      isRequired
                      name="phase.bpmnl"
                      formik={formik}
                      value={formik.values.bn.chargerIP.noLoad * formik.values.phase.bcmnl}
                      disabled
                    />
                  </td>
                  <td>
                    <FormikTextInput
                      type="text"
                      className="text-input"
                      isRequired
                      name="phase.bpmfl"
                      formik={formik}
                      value={formik.values.bn.chargerIP.fullLoad * formik.values.phase.b}
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row mt-3 m-2">
            <div className="col-12">
              <div className="note_div">
                <h3>Note</h3>
                <ol>
                  <li>Full Load: When all the Guns are in operations</li>
                  <li>No Load Condition: Charging is not happening with any of the charger gun - idle condition</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button
            type="button"
            className="prev-btn"
            onClick={() => {
              dispatch(changeStep(currentStep - 1));
            }}
          >
            Previous
          </button>

          {form?.status !== 'approved' && form?.status !== 'pending' ? (
            <button type="submit" className="submit-btn">
              Next
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                dispatch(changeStep(currentStep + 1));
              }}
              className="submit-btn"
            >
              Next
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

CircuitMeasurements.propTypes = {
  currentStep: PropTypes.number,
};

export default CircuitMeasurements;
