import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import RadioButton from 'components/inputs/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { changeStep, updateForm } from 'actions/formActions';
import { useFormik } from 'formik';
import FormikTextInput from 'components/inputs/FormikInputs';
import FormikDropDown from 'components/inputs/FormikDropDown';

const defaultValues = {
  storagePerson: '',
  storagePersonContact: '',
  storagePlace: '',
  storageAddress: '',
  outdoorStorageAddress: '',
  storageFrom: '',
  storageTo: '',
  assigneeComment: '',
};

const Storage = ({ currentStep }) => {
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [checked, setChecked] = useState('no');
  const [checked2, setChecked2] = useState('no');

  const { form } = useSelector((state) => state.forms);

  const dispatch = useDispatch();

  useEffect(() => {
    if (form !== null) {
      setInitialValues({
        storagePerson: form?.storagePerson || '',
        storagePersonContact: form?.storagePersonContact || '',
        storagePlace: form?.storagePlace || '',
        storageAddress: form?.storageAddress || '',
        storageFrom: form?.storageFrom || new Date().toISOString().slice(0, 10),
        storageTo: form?.storageTo || new Date().toISOString().slice(0, 10),
      });
      if (form?.storage) {
        setChecked(form?.storage);
      }
      if (form?.plasticCovering) {
        setChecked2(form?.plasticCovering);
      }
    }
  }, [JSON.stringify(form)]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (form?.status === 'approved') {
        // For approved forms, just navigate without updating
        dispatch(changeStep(currentStep + 1));
      } else if (form !== null && form?.id !== undefined) {
        values.storage = checked;
        values.plasticCovering = checked2;
        values.step = (currentStep + 1).toString();
        dispatch(updateForm(form?.id, values));
      } else {
        dispatch(changeStep(0));
      }
    },
  });
  const handleChange = (e) => {
    setChecked(e.target.value);
  };

  const handleChange2 = (e) => {
    setChecked2(e.target.value);
  };
  const options = [
    { label: 'Indoor', value: 'Indoor' },
    { label: 'Outdoor', value: 'Outdoor' },
  ];

  return (
    <>
      <div className="card-body">
        <form onSubmit={formik.handleSubmit}>
          <div className="row my-3">
            <div className="col-12">
              <div className="mb-3">
                <div className="storage-item d-flex gap-2 align-items-center">
                  <FormLabel htmlFor="storage" className="form-label">
                    Storage more than one week?
                  </FormLabel>
                  <div className=" d-flex gap-3">
                    <RadioButton
                      id="storageExtend_yes"
                      containerClassName="radio-field"
                      value="yes"
                      checked={checked === 'yes'}
                      onChange={handleChange}
                    >
                      Yes
                    </RadioButton>
                    <RadioButton id="storageExtend_no" containerClassName="radio-field" value="no" checked={checked === 'no'} onChange={handleChange}>
                      No
                    </RadioButton>
                  </div>
                </div>
              </div>
            </div>
            {checked === 'yes' && (
              <>
                <div className="col-6">
                  <div className="mb-3">
                    <FormikTextInput type="date" className="text-input" label="From" name="storageFrom" formik={formik} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <FormikTextInput type="date" className="text-input" label="To" name="storageTo" formik={formik} />
                  </div>
                </div>
              </>
            )}
          </div>
          {checked === 'yes' && (
            <>
              <div className="row my-3">
                <div className="col-6">
                  <div className="mb-3">
                    <FormikTextInput
                      type="text"
                      className="text-input"
                      label="Person Responsible For Storage Name"
                      placeholder="Enter Person Responsible For Storage Name"
                      name="storagePerson"
                      formik={formik}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <FormikTextInput
                      type="text"
                      className="text-input"
                      label="Person Responsible For Storage Number"
                      placeholder="Enter Person Responsible For Storage Number"
                      name="storagePersonContact"
                      formik={formik}
                    />
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-6">
                  <div className="mb-3">
                    <FormikDropDown
                      formik={formik}
                      label="Storage Place"
                      name="storagePlace"
                      options={options}
                      className="text-input"
                      placeholder="Select Storage Place"
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="mb-3">
                    <FormikTextInput
                      type="text"
                      className="text-input"
                      name="storageAddress"
                      formik={formik}
                      label="Storage Address"
                      placeholder="Enter Storage Address"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="row my-3">
            <div className="col-12">
              <div className="mb-3">
                <div className="storage-item d-flex gap-2 align-items-center">
                  <FormLabel htmlFor="storage" className="form-label">
                    Plastic Covering?
                  </FormLabel>
                  <div className=" d-flex gap-3">
                    <RadioButton
                      id="plasticCovering_yes"
                      containerClassName="radio-field"
                      value="yes"
                      checked={checked2 === 'yes'}
                      onChange={handleChange2}
                    >
                      Yes
                    </RadioButton>
                    <RadioButton
                      id="plasticCovering_no"
                      containerClassName="radio-field"
                      value="no"
                      checked={checked2 === 'no'}
                      onChange={handleChange2}
                    >
                      No
                    </RadioButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons">
            <button
              type="button"
              className="prev-btn"
              onClick={() => {
                dispatch(changeStep(currentStep - 1));
              }}
            >
              Previous
            </button>

            <button
              type="submit"
              className="submit-btn"
              onClick={() => {
                formik.handleSubmit();
                // Only change step if form is valid
                if (formik.isValid) {
                  dispatch(changeStep(currentStep + 1));
                }
              }}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

Storage.propTypes = {
  currentStep: PropTypes.number,
};

export default Storage;
