import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import formReducer from './formReducer';
import dashboardReducer from './dashboardReducer';
const rootReducer = combineReducers({
  auth: authReducer,
  users: userReducer,
  forms: formReducer,
  dashboard: dashboardReducer,
});
export default rootReducer;
