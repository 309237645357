import React, { useState } from 'react';
import { Formik } from 'formik';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import SitesyncLogo from 'assets/images/logo.png';
import { useDispatch } from 'react-redux';
import { SIGN_IN_REQUEST } from 'actions/authActions';
import withAuth from '../../hoc/withAuth';
import TextInput from 'components/inputs/Inputs';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const authError = useSelector((state) => state.auth.error);

  useEffect(() => {
    if (authError) {
      toast.error(authError, {
        autoClose: 8000, // Changed to 8000 milliseconds (8 seconds)
      });
    }
  }, [authError]);

  return (
    <>
      <div className="d-flex flex-column login-page">
        <div className="page page-center">
          <div className="container container-tight py-4">
            <div className="card card-sm">
              <div className="text-center">
                <Link to="/" className="navbar-brand navbar-brand-autodark">
                  <img src={SitesyncLogo} height="70" alt="Sitesync Logo" />
                  <h2>SiteSync</h2>
                </Link>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={{ email: '', password: '' }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = 'Required email address';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                      errors.email = 'Invalid email address';
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    dispatch({ type: SIGN_IN_REQUEST, payload: values });
                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <>
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <TextInput
                            type="email"
                            name="email"
                            placeholder="Enter Email Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            autoComplete="off"
                            size="md"
                          />
                          <div className="error-message">{errors.email && touched.email && errors.email}</div>
                        </div>
                        <div className="mb-2">
                          <div className="input-group input-group-flat">
                            <TextInput
                              type={showPassword ? 'text' : 'password'}
                              name="password"
                              placeholder="Enter Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              autoComplete="off"
                              size="md"
                            />
                            <div className="error-message">{errors.password && touched.password ? errors.password : null}</div>
                            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <IconEye /> : <IconEyeOff />}
                            </span>
                          </div>
                        </div>

                        <div className="form-footer">
                          <button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>
                            Login
                          </button>
                        </div>
                        <div className="mt-3 text-center">
                          <span className="forgot-password">
                            <Link to={'/forgotPassword'}>Forgot Password</Link>
                          </span>
                        </div>
                      </form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(Login);
