import React from 'react';
import PropTypes from 'prop-types';
function TagsInput({ value: tagValue, onChange }) {
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      const value = e.target.value;
      if (!value.trim()) return;
      onChange([...tagValue, value]);
      e.target.value = '';
    }
  }

  function removeTag(index) {
    onChange(tagValue.filter((el, i) => i !== index));
  }

  return (
    <div className="tags-input-container ">
      {tagValue?.map((tag, index) => (
        <div className="tag-item text-input " key={index}>
          <span className="text">{tag}</span>
          <span className="close" onClick={() => removeTag(index)}>
            &times;
          </span>
        </div>
      ))}
      <input onKeyDown={handleKeyDown} type="text" className="tags-input text-input" placeholder="Enter Static IPS" />
    </div>
  );
}

TagsInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default TagsInput;
