import { FETCH_DASHBOARD, FETCH_DASHBOARD_FAIL, FETCH_DASHBOARD_SUCCESS } from 'actions/dashboardActions';
import { toast } from 'react-toastify';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { failMessages } from 'utils/constants/messages';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REHYDRATE: {
      return {
        ...state,
        ...payload?.dashboard,
      };
    }
    case FETCH_DASHBOARD: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_DASHBOARD_SUCCESS: {
      return {
        ...state,
        data: payload,
        loading: false,
      };
    }
    case FETCH_DASHBOARD_FAIL: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default dashboardReducer;
