import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/general/Avatar';
import { useNavigate } from 'react-router-dom';
const Table = ({ data, handleDeleteBtn = () => {} }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="card">
        <div className="table-responsive">
          <table className="table card-table table-vcenter text-nowrap datatable">
            <tbody>
              {data.length > 0 ? (
                data.map((row) => (
                  <tr key={row.id}>
                    <td className="user_name">
                      <div className="d-flex align-items-center gap-2">
                        <Avatar role={row?.role} name={`${row?.name} ${row?.lastName ? row?.lastName : ''}`} />
                        <span>{`${row?.name} ${row?.lastName ? row?.lastName : ''}`}</span>
                      </div>
                    </td>
                    <td>{row.email}</td>
                    <td>{row.phone ?? '-'}</td>
                    <td>
                      {row.reportingManager?.name
                        ? `${row.reportingManager?.name} ${row.reportingManager?.lastName ? row.reportingManager?.lastName : ''}`
                        : '-'}
                    </td>
                    <td>
                      <div className="d-flex gap-2 all_btn justify-content-end">
                        <div className="role_btn">{row.role === 'admin' ? 'Admin' : 'CE'}</div>
                        <div
                          className="edit_btn"
                          onClick={() => {
                            navigate('/usermanagement/edit/' + row.id);
                          }}
                        >
                          Edit
                        </div>
                        <div
                          className="delete_btn"
                          onClick={() => {
                            handleDeleteBtn(row.id);
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="user_name"> No records found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  handleDeleteBtn: PropTypes.func,
};

export default Table;
