import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { IconCaretDownFilled } from '@tabler/icons-react';
import { FormLabel } from 'react-bootstrap';
import * as _ from 'lodash';

const Dropdown = ({ label, placeholder, options, isRequired, name, formik, readOnly = false }) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const displayOption = selectedOption?.label || placeholder;
  const dropdownRef = useRef(null);
  let fieldValue = _.get(formik.values, name);

  useEffect(() => {
    if (fieldValue) {
      let selected = options.find((option) => option.value === fieldValue);
      setSelectedOption(selected);
    }
  }, [fieldValue]);

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Clicked outside the dropdown, close it
      setOpen(false);
    }
  };

  const toggleOpen = () => {
    setOpen(!open);
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    formik.setFieldTouched(name, true);
    formik.setFieldValue(name, option.value);
    setOpen(false); // Close dropdown on change
  };
  return (
    <>
      {label && (
        <FormLabel>
          {label} {isRequired && <span className="require--star">*</span>}
        </FormLabel>
      )}
      <div ref={dropdownRef} className="dropdown">
        <div className={`label ${selectedOption ? '' : 'place-holder'}`} onClick={readOnly ? () => {} : toggleOpen}>
          {displayOption}
          <IconCaretDownFilled className="caret" />
        </div>

        {open && (
          <div className="options">
            {options.map((option) => (
              <div key={option.value} className={option.value === selectedOption ? 'selected' : ''} onClick={() => handleOptionClick(option)}>
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
      {_.get(formik.errors, name) && _.get(formik.touched, name) && <span className="text-danger">{_.get(formik.errors, name)}</span>}
    </>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  formik: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default Dropdown;
