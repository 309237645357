import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from 'view/login/index';
import ForgotPassword from 'view/forgotPassword';
import NewPassword from 'view/forgotPassword/newPassword';
import Dashboard from 'view/dashboard/dashboard';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import PrivateRoute from 'components/privateRoute/PrivateRoute';
// import PreFormList from 'view/preForm';
import Form from 'view/allForms/form';
// import PostFormList from 'view/postForm';
// import PostForm from 'view/postForm/form';
import AllForms from 'view/allForms';
import UserManagement from 'view/userManagement';
import User from 'view/userManagement/user';
import newForm from 'view/allForms/form';
import Assignment from 'view/assignment/assignment';
const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/newpassword" element={<NewPassword />} />
          <Route path="/dashboard" element={<PrivateRoute title={'Dashboard'} roles={['admin', 'engineer']} component={Dashboard} />} />
          <Route path="/allforms" element={<PrivateRoute title={'All Forms'} roles={['admin', 'engineer']} component={AllForms} />} />
          <Route path="/allForms/form" element={<PrivateRoute title={'All Forms'} roles={['admin', 'engineer']} component={newForm} />} />
          <Route path="/usermanagement" element={<PrivateRoute title={'User Management'} roles={['admin']} component={UserManagement} />} />
          <Route path="/usermanagement/add" element={<PrivateRoute title={'Add User'} roles={['admin']} component={User} />} />
          <Route path="/usermanagement/edit/:userId" element={<PrivateRoute title={'Edit User'} roles={['admin']} component={User} />} />
          <Route path="/allForms/form/edit/:formId" element={<PrivateRoute title={'Edit Form'} roles={['admin', 'engineer']} component={Form} />} />
          <Route path="/allForms/form/view/:formId" element={<PrivateRoute title={'View Form'} roles={['admin', 'engineer']} component={Form} />} />
          <Route path="/allForms/form/review/:formId" element={<PrivateRoute title={'Review Form'} roles={['admin', 'engineer']} component={Form} />} />
          <Route path="/assignment" element={<PrivateRoute title={'Assignment'} roles={['admin']} component={Assignment} />} />
          {/* <Route
            path="/pre-installation"
            element={<PrivateRoute title={'Pre Installation Forms'} roles={['admin', 'engineer']} component={PreFormList} />}
          /> */}
          {/* <Route
            path="/pre-installation/add"
            element={<PrivateRoute title={'Pre Installation Form'} roles={['admin', 'engineer']} component={PreForm} />}
          /> */}
          {/* <Route
            path="/post-installation"
            element={<PrivateRoute title={'Post Installation Forms'} roles={['admin', 'engineer']} component={PostFormList} />}
          />
          <Route
            path="/post-installation/add"
            element={<PrivateRoute title={'Post Installation Form'} roles={['admin', 'engineer']} component={PostForm} />}
          />
          <Route
            path="/post-installation/edit/:formId"
            element={<PrivateRoute title={'Edit Post Installation Form'} roles={['admin', 'engineer']} component={PostForm} />}
          /> */}
        </Routes>
        <ToastContainer position="top-right" theme="colored" />
        <Toaster />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
