import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserSelectionModel from 'components/general/UserSelectionModel';
import UserDropdown from './UserDropdown';
import { useDispatch } from 'react-redux';
import { ASSIGN_CHARGER } from 'actions/formActions';
import Pagination from 'components/general/Pagination';
import { toast } from 'react-toastify';

const indianStatesAndUTs = [
  'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep', 'Delhi', 'Puducherry', 'Ladakh', 'Jammu and Kashmir'
];

const Table = ({ data, users }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const rowsPerPage = 10;

  useEffect(() => {
    setFilteredData(
      data
        .filter((row) =>
          row.charging_station.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.charger_id.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .filter((row) => !selectedState || row.charging_station.address.toLowerCase().includes(selectedState.toLowerCase()))
    );
    setPage(0); // Reset to first page when filters change
  }, [searchTerm, selectedState, data]);

  useEffect(() => {
    if (sortConfig.key !== null) {
      const sortedData = [...filteredData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      setFilteredData(sortedData);
    }
  }, [sortConfig]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleAssignClick = (row) => {
    setSelectedRow(row);
    setShowPopup(true);
  };

  const handleUserSelection = (userId, user) => {
    setSelectedUser(() => ({
      [selectedRow.id]: user,
    }));
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const handleClose = async (submit) => {
    if (submit === true) {
      dispatch({
        type: ASSIGN_CHARGER,
        payload: {
          stationName: selectedRow?.charging_station?.name,
          chargerId: selectedRow?.charger_id,
          submittedBy: selectedUser[selectedRow?.id]?.id,
          reportingTo: selectedUser[selectedRow?.id]?.reportingManager?.id,
        },
      });
      toast.success('User Assigned');
    }
    setSelectedRow(null);
    setSelectedUser({});
    setShowPopup(false);
  };

  const handleSearch = () => {
    setFilteredData(
      data
        .filter((row) =>
          row.charging_station.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.charger_id.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .filter((row) => !selectedState || row.charging_station.address.toLowerCase().includes(selectedState.toLowerCase()))
    );
    setPage(0); // Reset to first page when search button is clicked
  };

  const handleClear = () => {
    setSearchTerm('');
    setSelectedState('');
    setFilteredData(data);
    setPage(0); // Reset to first page when clearing filters
  };

  return (
    <>
      <div className="card assignment">
        <div className="table-responsive">
          <div className="filters">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search by Station Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="state-filter">
              <select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                className="form-control"
              >
                <option value="">Select State/UT</option>
                {indianStatesAndUTs.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
            <button className="btn btn-primary search-btn" onClick={handleSearch}>
              Search
            </button>
            <button className="btn btn-secondary clear-btn" onClick={handleClear}>
              Clear All
            </button>
          </div>
          <table className="table card-table table-vcenter text-nowrap datatable pre-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('charging_station.name')}>
                  Station Name{' '}
                  {sortConfig.key === 'charging_station.name' && (
                    <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('charger_id')}>
                  ChargerID / OCPP ID{' '}
                  {sortConfig.key === 'charger_id' && (
                    <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                  )}
                </th>
                <th onClick={() => handleSort('createdAt')}>
                  Date{' '}
                  {sortConfig.key === 'createdAt' && (
                    <span>{sortConfig.direction === 'ascending' ? '▲' : '▼'}</span>
                  )}
                </th>
                <th>Assignee</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <tr key={row.id}>
                    <td>{row.charging_station.name}</td>
                    <td>{row.charger_id}</td>
                    <td>{new Date(row.createdAt).toLocaleString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        timeZone: 'Asia/Kolkata'
                      })}
                    </td>
                    <td>
                      <button className="assign_btn" onClick={() => handleAssignClick(row)}>
                        Assign
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="user_name" colSpan={4}>
                    No records found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        total={filteredData.length}
        handlePageClick={onPageChange}
        currentPage={page + 1}
        limit={rowsPerPage}
        totalPages={Math.ceil(filteredData.length / rowsPerPage)}
      />
      {showPopup && (
        <UserSelectionModel
          show={showPopup}
          onHide={() => handleClose(false)}
          onConfirm={() => handleClose(true)}
          title="Assign User"
          message={
            <UserDropdown
              users={users}
              onSelectUser={handleUserSelection}
              selectedUser={selectedUser[selectedRow?.id]}
            />
          }
        />
      )}
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  users: PropTypes.array.isRequired,
};

export default Table;
