import React from 'react';
import PropTypes from 'prop-types';

const TextareaField = ({ value, onChange, rows, readonly, className, placeholder }) => {
  return (
    <div>
      <textarea
        rows={rows}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`form-control ${className}`}
        readOnly={readonly}
      ></textarea>
    </div>
  );
};
TextareaField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.number,
  readonly: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};
TextareaField.defaultProps = {
  readonly: false,
};
export default TextareaField;
