import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { cookie } from 'utils/cookies';
import Sidebar from 'components/sidebar/index';
import Header from 'components/header';
import Footer from 'components/footer';
const PrivateRoute = ({ roles, component: Component, title }) => {
  const token = cookie.get('token');
  const userRole = cookie.get('user-role');
  if (roles.includes(userRole) && token) {
    return (
      <>
        <div className="page">
          <Sidebar />
          <Header title={title} />
          <div className="page-wrapper">
            <Component />
          </div>
          <Footer />
        </div>
      </>
    );
  } else {
    cookie.clean();
    return <Navigate to="/login" />;
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.node]),
  roles: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default PrivateRoute;
