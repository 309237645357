import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { authHeader } from 'utils/api';
import queryString from 'query-string';

export const handleImage = async (image, fileName) => {
  if (!image) {
    return '';
  }
  try {
    const response = await axios({
      method: 'get',
      headers: {
        ...authHeader(),
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    });

    if (response.status === 201) {
      const signedRequest = _.get(response, 'data.signedRequest', '');
      return await handleUploadFile(signedRequest, image);
    } else {
      toast.error(response.data.message);
      return '';
    }
  } catch (error) {
    console.error('Error -> ', error);
    return '';
  }
};

const handleUploadFile = async (url, imageData) => {
  try {
    const fileType = _.get(imageData, 'type', '');

    const res = await axios({
      method: 'PUT',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    });
    if (res.status === 200) {
      const imgUrlData = queryString.parseUrl(url);
      return imgUrlData.url;
    } else {
      toast.error(res.data.message);
      return '';
    }
  } catch (error) {
    console.error('Error -> ', error);
    return '';
  }
};
