import { passwordValidationRegex, phoneValidationRegex } from 'utils/constants/constants';
import { formValidation } from 'utils/constants/messages';
import * as Yup from 'yup';

export const addUserSchema = Yup.object().shape({
  name: Yup.string().required(formValidation.required),
  lastName: Yup.string().required(formValidation.required),
  email: Yup.string().email(formValidation.invalidEmail).required(formValidation.required),
  phone: Yup.string().matches(phoneValidationRegex, formValidation.invalidPhone).min(8).required(formValidation.required),
  password: Yup.string().matches(passwordValidationRegex, formValidation.invalidPassword).required(formValidation.required),
  confirmPassword: Yup.string()
    .required(formValidation.required)
    .oneOf([Yup.ref('password'), null], formValidation.invalidConfirmPassword),
  role: Yup.string().required(formValidation.required),
});

export const updateUserSchema = Yup.object().shape({
  name: Yup.string().required(formValidation.required),
  lastName: Yup.string().required(formValidation.required),
  email: Yup.string().email(formValidation.invalidEmail).required(formValidation.required),
  phone: Yup.string().matches(phoneValidationRegex, formValidation.invalidPhone).min(8).required(formValidation.required),
  password: Yup.string().matches(passwordValidationRegex, formValidation.invalidPassword).nullable(true),
  confirmPassword: Yup.string().when('password', {
    is: (value) => !!value,
    then: (schema) =>
      schema.required(formValidation.required).oneOf([Yup.ref('password'), null], formValidation.invalidConfirmPassword),
  }),
  role: Yup.string().required(formValidation.required),
});
