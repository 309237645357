import {
  ADD_FORM,
  ADD_FORM_ERROR,
  ADD_FORM_SUCCESS,
  CHANGE_STEP,
  DELETE_FORM,
  DELETE_FORM_ERROR,
  DELETE_FORM_SUCCESS,
  FETCH_FORM,
  FETCH_FORM_FAIL,
  FETCH_FORM_SUCCESS,
  FETCH_CHARGERS,
  FETCH_CHARGERS_FAIL,
  FETCH_CHARGERS_SUCCESS,
  FETCH_FORMS,
  FETCH_FORMS_FAIL,
  FETCH_FORMS_SUCCESS,
  UNSET_FORM,
  UPDATE_FORM,
  UPDATE_FORM_ERROR,
  UPDATE_FORM_SUCCESS,
  ASSIGN_CHARGER,
  ASSIGN_CHARGER_SUCCESS,
  ASSIGN_CHARGER_FAIL,
} from 'actions/formActions';
import { toast } from 'react-toastify';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { failMessages, successMessages } from 'utils/constants/messages';

const initialState = {
  chargers: [],
  forms: [],
  form: null,
  currentStep: 0,
  loading: false,
  error: null,
};

const FormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REHYDRATE: {
      return {
        ...state,
        ...payload?.Forms,
      };
    }
    case CHANGE_STEP: {
      return { ...state, currentStep: payload };
    }
    case FETCH_CHARGERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_CHARGERS_SUCCESS: {
      return {
        ...state,
        chargers: payload,
        loading: false,
      };
    }
    case FETCH_CHARGERS_FAIL: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FORMS: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_FORMS_SUCCESS: {
      const { results, ...rest } = payload;
      return {
        ...state,
        forms: results,
        ...rest,
        loading: false,
      };
    }
    case FETCH_FORMS_FAIL: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_FORM: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_FORM_SUCCESS: {
      return {
        ...state,
        form: payload,
        currentStep: payload.status === 'draft' ? parseInt(payload.step) : 0,
        loading: false,
      };
    }
    case FETCH_FORM_FAIL: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    case UNSET_FORM: {
      return {
        ...state,
        form: null,
        currentStep: 0,
      };
    }
    case ASSIGN_CHARGER: {
      return {
        ...state,
        loading: true,
      };
    }
    case ASSIGN_CHARGER_SUCCESS: {
      return {
        ...state,
        chargers: state.chargers.filter((charger) => charger.charger_id !== payload.chargerId),
        loading: false,
      };
    }
    case ASSIGN_CHARGER_FAIL: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_FORM: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_FORM_SUCCESS: {
      return {
        ...state,
        form: payload,
        currentStep: parseInt(payload.step),
        loading: false,
      };
    }
    case ADD_FORM_ERROR: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_FORM: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_FORM_SUCCESS: {
      return {
        ...state,
        form: payload,
        currentStep: parseInt(payload.step),
        loading: false,
      };
    }
    case UPDATE_FORM_ERROR: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_FORM: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_FORM_SUCCESS: {
      toast.success(successMessages.deleteForm);
      return {
        ...state,
        forms: state.forms.filter((form) => form.id !== payload),
        loading: false,
      };
    }
    case DELETE_FORM_ERROR: {
      toast.error(failMessages.error);
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return { ...state };
  }
};
export default FormReducer;
